import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Modal,
  Space,
  Button,
  Form,
  Spin,
  message,
  Tooltip,
  Select,
} from "antd";
import {
  SearchOutlined,
  ImportOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { UserTableStyleWrapper } from "../../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../../Customer/Style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { Popover } from "../../../components/popup/popup";
import { ExportCustomer } from "../../../redux/customer/actionCreator";
import commonFunction from "../../../utility/commonFunctions";
import { useHistory } from "react-router-dom";
import "../../Customer/customer.css";
import { getItem, setItem } from "../../../utility/localStorageControl";
import moment from "moment";
import {
  ChartjsBarChartTransparent,
  ChartjsDonutChart,
} from "../../../components/charts/chartjs";
import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import {
  getPaymentreceived,
  getPaymnetMode,
} from "../../../redux/dashboard/actionCreator";
import { CardBarChart } from "../../dashboard/style";
import {
  getReceiptsBySalesId,
  handlePaymnetModeDate,
} from "../../../redux/receipts/actionCreator";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import { getSingleReservation } from "../../../redux/reservation/actionCreator";
function capitalizeFirstLetter(str) {
  if (str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
}
const PaymnetReceived = () => {
  const [rsSymbol, setRsSymbol] = useState(
    getItem("setupCache")?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)
          ?.length > 0
        ? /\(([^)]+)\)/.exec(getItem("setupCache").shopDetails.rs_symbol)[1]
        : getItem("setupCache").shopDetails.rs_symbol
      : "₹"
  );
  const userDetails = getItem("userDetails");

  const { startDate, endDate, dateChange, registerList } = useSelector(
    (state) => {
      return {
        startDate: state.receipts.paymnetModeStartDate
          ? state.receipts.paymnetModeStartDate
          : moment()
              .startOf("month")
              .format("YYYY-MM-DD"),
        endDate: state.receipts.paymnetModeEndDate
          ? state.receipts.paymnetModeEndDate
          : moment()
              .endOf("month")
              .format("YYYY-MM-DD"),
        dateChange: state.receipts.paymnetModeDateChange,
        registerList: state.register.RegisterList.filter((value) => {
          if (
            userDetails?.business_type == undefined ||
            userDetails?.business_type == "food_and_drink"
          ) {
            return value.type == "restaurant";
          } else {
            return true;
          }
        }),
      };
    }
  );
  const dispatch = useDispatch();
  let colorsArray = [
    "#FF5733",
    "#FFC300",
    "#36DBCA",
    "#49FF33",
    "#336BFF", // Example custom colors
    "#FF33B7",
    "#FF3333",
    "#33FF5D",
    "#333BFF",
    "#5D33FF",
    "#FFE333",
    "#B633FF",
    "#33EFFF",
    "#FF3333",
    "#33FFC9",
    "#C933FF",
    "#33FFAA",
    "#FF3333",
    "#33FF9C",
    "#FF3333",
    "#33FF67",
    "#FF3333",
    "#5B33FF",
    "#33FF47",
    "#FF3333",
    "#3389FF",
    "#FF3333",
    "#33FF33",
    "#FF3333",
    "#33D4FF",
    "#FF3333",
    "#3350FF",
    "#FF3333",
    "#33FF33",
    "#FF3333",
    "#33FF4D",
    "#FF3333",
    "#3397FF",
    "#FF3333",
    "#33FFC9",
    "#FF3333",
    "#33FFD8",
    "#FF3333",
    "#33FF5D",
    "#FF3333",
    "#33FF8E",
    "#FF3333",
    "#33FFA7",
    "#FF3333",
    "#33FFDE",
  ];
  const [dateWisePatmnetList, setDateWisePatmnetList] = useState([]);
  const [totalPaymnetObj, setTotalPaymnetObj] = useState({});
  const [totalRS, setTotalRS] = useState(0);
  const [loader, setLoader] = useState(true);
  const history = useHistory();
  const [customObjtype, setCustomObjType] = useState([]);
  const csvLinkRef = useRef();
  let [allPaymentMode, setAllPaymentMode] = useState([]);
  let [allStatus, setAllPaymentStatus] = useState([
    { text: "Paid", value: "Paid" },
    { text: "Cancel", value: "Cancel" },
    { text: "Refund", value: "Refund" },
  ]);
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [currentRegisterData, setCurrentRegisterData] = useState("allRegister");
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);

  async function fetchPaymnetData(startDate, endDate) {
    setLoader(true);
    const response = await dispatch(
      getPaymentreceived(startDate, endDate, currentRegisterData, "payment")
    );
    setLoader(false);
    if (response && !response.error) {
      if (response.saleSummaruydashboardDateWiseDetails) {
        let { saleSummaruydashboardDateWiseDetails } = response;
        let paymentMode = [];
        setDateWisePatmnetList(
          saleSummaruydashboardDateWiseDetails.map((h) => {
            let obj = { ...h };
            obj["date"] = commonFunction.convertToDate(
              h.date,
              "MMM DD, Y, h:mm A"
            );
            obj["paymentMode"] = capitalizeFirstLetter(h.paymentMode);

            if (!paymentMode.find((j) => j.value == obj["paymentMode"])) {
              paymentMode.push({
                text: obj["paymentMode"],
                value: obj["paymentMode"],
              });
            }
            console.log("paymentMode", paymentMode);

            return obj;
          })
        );
        setAllPaymentMode([...paymentMode]);
      }
    } else if (response) {
      message.error(response.message);
    }
  }

  const [reservationLoading, setReservationLoading] = useState({
    id: "",
    loading: false,
  });

  const handleReservation = async (id, type, recordId) => {
    console.log("recordId", recordId);

    if (reservationLoading.loading == false && type == "hotel") {
      setReservationLoading({
        id: recordId,
        loading: true,
      });
      let response = await dispatch(getSingleReservation(id));
      if (response && response.data) {
        history.push("/reservation", { reservationData: response.data });
        setReservationLoading({
          id: "",
          loading: false,
        });
      }
    } else {
      setReservationLoading({
        id: recordId,
        loading: true,
      });
      let response = await dispatch(getReceiptsBySalesId(id));
      if (response && response.ReceiptsIdData?._id) {
        history.push(`/receipts/${response.ReceiptsIdData?._id}`);
        setReservationLoading({
          id: "",
          loading: false,
        });
      }
    }
  };

  async function setStartDateAndDate(startDate, endDate) {
    dispatch(handlePaymnetModeDate(startDate, endDate, false));
  }
  useEffect(() => {
    let startDate = moment().format("YYYY-MM-DD");
    let endDate = moment().format("YYYY-MM-DD");
    fetchPaymnetData(startDate, endDate);
    setStartDateAndDate(startDate, endDate);
  }, []);

  const didMount = useRef(false);
  useEffect(() => {
    if (didMount.current) {
      if (dateChange) {
        fetchPaymnetData(startDate, endDate);
      }
    } else {
      didMount.current = true;
    }
  }, [dateChange, startDate, endDate]);
  const didMountRegister = useRef(false);

  useEffect(() => {
    if (didMountRegister.current) {
      if (currentRegisterData) {
        fetchPaymnetData(startDate, endDate);
      }
    } else {
      didMountRegister.current = true;
    }
  }, [currentRegisterData]);

  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  console.log("dateWisePatmnetList", dateWisePatmnetList);
  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      fixed: "left",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
    },
    {
      title: "⁠Reference Number",
      dataIndex: "referenceNumber",
      key: "referenceNumber",
      align: "left",
      render(text, record) {
        return {
          children: (
            <div>
              {reservationLoading?.id == record?._id &&
              reservationLoading.loading ? (
                "Loading..."
              ) : (
                <div style={{ color: "#008cba", display: "inline" }}>
                  {text}
                </div>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: "Customer Name",
      dataIndex: "customerName",
      key: "customerName",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text ? text : "-"}</div>,
        };
      },
    },
    {
      title: "Mobile Number",
      dataIndex: "mobileNumber",
      key: "mobileNumber",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text ? text : "-"}</div>,
        };
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
      filters: allStatus,
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "⁠Payment Mode",
      dataIndex: "paymentMode",
      key: "paymentMode",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text}</div>,
        };
      },
      filters: allPaymentMode,
      onFilter: (value, record) => record.paymentMode.indexOf(value) === 0,
    },

    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text ? text : "-"}</div>,
        };
      },
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      align: "left",
      render(text, record) {
        return {
          children: (
            <div>
              {rsSymbol}
              {text}
            </div>
          ),
        };
      },
    },
  ];

  const handleDownloadCSV = () => {
    csvLinkRef.current.link.click();
  };
  const handleDownloadPdf = () => {
    let col = [...columns, ...customObjtype].filter(
      (val) => val.dataIndex != "action"
    );
    const doc = new jsPDF();
    doc.text("PaymentReceived", 10, 10);
    doc.autoTable({
      startY: 20,
      head: [
        col.map((column) =>
          column.title == "Reference Number"
            ? "Number"
            : column.title == "⁠Payment Mode"
            ? "Mode"
            : column.title
        ),
      ],
      body: dateWisePatmnetList.map((row) =>
        col.map((column) => row[column.dataIndex])
      ),
    });

    doc.save("PaymentReceived.pdf");
  };

  const handleDownloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(
      dateWisePatmnetList.map((k) => ({
        Date: k.date,
        "⁠Reference Number": k.referenceNumber,
        "Customer Name": k.customerName,
        "Mobile Number": k.mobileNumber,
        "Payment Mode": k.paymentMode,
        Status: k.status,
        Notes: k.notes,
        Amount: k.amount,
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "PaymentReceived.xlsx");
  };

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadPdf();
        }}
      >
        <FeatherIcon size={16} icon="book-open" className="popupIcons" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadExcel();
        }}
      >
        <FeatherIcon size={16} icon="x" className="popupIcons" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          handleDownloadCSV();
        }}
      >
        <FeatherIcon size={16} icon="file" className="popupIcons" />
        <span>CSV</span>
      </NavLink>
    </>
  );
  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div
                  className="table_titles"
                  style={{ marginBottom: 10, marginTop: 10 }}
                >
                  <h2>Payment Received</h2>
                </div>

                <Select
                  className="customBorderRadius"
                  style={{
                    width: "100%",
                    marginBottom: 10,
                    marginTop: 10,
                    textAlign: "center",
                    alignContent: "center",
                    alignItems: "center",
                    borderRadius: "3px",
                  }}
                  value={currentRegisterData}
                  onChange={(val) => setCurrentRegisterData(val)}
                >
                  <Option value={"allRegister"} style={{ textAlign: "center" }}>
                    All Register
                  </Option>
                  {registerList?.reverse()?.map((val) => {
                    return (
                      <Option value={val._id} style={{ textAlign: "center" }}>
                        {val.register_name}
                      </Option>
                    );
                  })}
                </Select>
              </>
            }
            buttons={[
              <div
                key="2"
                className="page-header-actions custom_action"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                <div className="actionBtn">
                  <Tooltip
                    title={
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        Showing records from{" "}
                        {startDate
                          ? moment(startDate).format("MMMM Do YYYY")
                          : moment()
                              .startOf("month")
                              .format("MMMM Do YYYY")}
                        {" to"}{" "}
                        {endDate
                          ? moment(endDate).format("MMMM Do YYYY")
                          : moment()
                              .endOf("month")
                              .format("MMMM Do YYYY")}
                      </p>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: "18px",
                      }}
                    />
                  </Tooltip>
                  <CalendarButtonPageHeader key="7" type="payment-mode" />
                </div>
              </div>,
              <div
                key="1"
                className="page-header-actions"
                style={{ marginBottom: 10, marginTop: 10 }}
              >
                <Popover
                  placement="bottomLeft"
                  content={content}
                  trigger="hover"
                >
                  <Button size="small" type="white">
                    <FeatherIcon icon="download" size={14} />
                    Export
                  </Button>
                </Popover>
              </div>,
            ]}
          />
        </CardToolbox>
        <Row gutter={15}>
          <CSVLink
            style={{ display: "none" }}
            data={dateWisePatmnetList}
            headers={[...columns, ...customObjtype]
              .filter((val) => val.dataIndex != "action")
              .map((column) => ({
                label: column.title,
                key: column.dataIndex,
              }))}
            filename={"PaymentReceived.csv"}
            ref={csvLinkRef}
          >
            Hidden Download CSV Link
          </CSVLink>
          <Col md={24}>
            <Row>
              <Cards headless>
                <UserTableStyleWrapper>
                  <div className="contact-table">
                    <TableWrapper className="table-responsive">
                      {loader ? (
                        <Table
                          locale={locale}
                          rowKey="_id"
                          size="small"
                          dataSource={[]}
                          columns={columns}
                          fixed={true}
                          scroll={windowWidth < 1050 ? { x: 700 } : {}}
                          pagination={false}
                        />
                      ) : (
                        <Table
                          scroll={windowWidth < 1050 ? { x: 700 } : {}}
                          rowKey="_id"
                          size="small"
                          dataSource={dateWisePatmnetList}
                          columns={[...columns, ...customObjtype]}
                          fixed={true}
                          onRow={(row) => ({
                            onClick: () => {
                              console.log("rowrowrow", row);
                              if (row.reservationId) {
                                handleReservation(
                                  row.reservationId,
                                  "hotel",
                                  row._id
                                );
                              } else {
                                handleReservation(row.saleId, "sale", row._id);
                              }
                            },
                          })}
                          pagination={{
                            total: dateWisePatmnetList.length,
                            showSizeChanger:
                              dateWisePatmnetList.length > 10 ? true : false,
                          }}
                        />
                      )}
                    </TableWrapper>
                  </div>
                </UserTableStyleWrapper>
              </Cards>
            </Row>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default PaymnetReceived;
