import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Modal,
  Space,
  Button,
  Form,
  Spin,
  message,
  Tooltip,
  Select,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  ImportOutlined,
  ExportOutlined,
  CloseOutlined,
  ExclamationCircleOutlined,
  FilterFilled,
} from "@ant-design/icons";
import { UserTableStyleWrapper } from "../../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../../Customer/Style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";

import { LoadingOutlined } from "@ant-design/icons";
import { Popover } from "../../../components/popup/popup";
import {
  getCustomerList,
  filterListData,
  ExportCustomer,
} from "../../../redux/customer/actionCreator";

import commonFunction from "../../../utility/commonFunctions";
import { useHistory } from "react-router-dom";
import "../../Customer/customer.css";
import { getItem, setItem } from "../../../utility/localStorageControl";
import {
  exportRooms,
  getMasterList,
  getRoomReservation,
} from "../../../redux/HotelReports/actionCreator";
import moment from "moment";
import { getSingleReservation } from "../../../redux/reservation/actionCreator";
import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import { handleRoomReservationDate } from "../../../redux/receipts/actionCreator";
const RoomReservation = () => {
  const { path } = useRouteMatch();
  let location = useLocation();
  const dispatch = useDispatch();
  const [sizeOfData, setSize] = useState(10);
  const [startdate, setstartdate] = useState();
  const [DateRanged, setDateRange] = useState("Today");
  const [type, settype] = useState(
    location?.state?.status ? location.state.status : ""
  );
  const [vendorSearchForm] = Form.useForm();
  const [resetLoader, setResetLoader] = useState(false);
  console.log("typetypetype", type);
  const [endDate1, setenddate] = useState();
  let searchInput = useRef(null);
  const history = useHistory();
  const [form] = Form.useForm();
  const [changePage, setChangePage] = useState(1);
  const [totalCustomer, setTotalCustomer] = useState();
  const [CustomerListData, setCustomerListData] = useState([]);
  const [roomReservation, setRoomReservation] = useState([]);
  const [exportType, setExportType] = useState();
  const [loading, setLoading] = useState(false);
  let isMounted = useRef(true);
  const { Search } = Input;
  const [modalVisible, setModelVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  const { currentRegisterData, startDate, endDate } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
      startDate: state.receipts.roomReservationStartDate,
      endDate: state.receipts.roomReservationEndDate,
    };
  });
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [vendorFilter, setVendorFilter] = useState(false);
  const [vendorSearchVisible, setVendorSearchVisible] = useState(false);
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");
  let [filterObj, setFilterObj] = useState(
    type == "cancelled" ? { isCancelled: true } : {}
  );
  let searchText = "";
  async function fetchRoomReservation() {
    setLoader(true);
    const response = await dispatch(
      getRoomReservation(currentRegisterData?._id, changePage, filterObj)
    );
    setLoader(false);
    setResetLoader(false);
    if (response && !response.error) {
      setRoomReservation(response.data);
      setVendorSearchVisible(false);
    } else if (response) {
      message.error(response.message);
    }
  }
  useEffect(() => {
    fetchRoomReservation();
  }, [filterObj, changePage]);

  let changePageData = async (value) => {
    setChangePage(value);
  };
  useEffect(() => {
    return () => {
      dispatch(handleRoomReservationDate(null, null));
    };
  }, []);
  let email = localStorage.getItem("email_id");
  const onSubmit = async (values) => {
    setLoading(true);
    if (loading) {
      setModelVisible(false);
      setLoading(false);
    }
    values.type = exportType;
    values.register_id = currentRegisterData?._id;
    if (DateRanged === "custom") {
      values.endDate = endDate1;
      values.startDate = startdate;
    }
    console.log("values", values);
    if (type == "cancelled") {
      values["isCancelled"] = true;
    }
    let ExportCustomerAPI = await dispatch(exportRooms(values));
    if (!ExportCustomerAPI.error) {
      setLoading(false);
      setModelVisible(false);
    }
  };

  const handleCancel = (e) => {
    setModelVisible(false);
  };

  const onSearch = async (value) => {
    let searchtext = value;
    if (searchtext === "") {
      delete filterObj.reservationStringId;
      setFilterObj({ ...filterObj });
    } else {
      setFilterObj({ ...filterObj, reservationStringId: searchtext });
    }
  };
  useEffect(() => {
    if (startDate) {
      setFilterObj({ ...filterObj, startDate: startDate, endDate: endDate });
    }
  }, [startDate]);
  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("CSV");
        }}
      >
        <FeatherIcon size={16} icon="file" />
        <span>CSV</span>
      </NavLink>
    </>
  );
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: "customerInfo",
      key: "customerInfo",
      fixed: "left",
      render(text, record) {
        return {
          children: <div>{text?.name}</div>,
        };
      },
    },

    {
      title: "REFERENCE",
      dataIndex: "reservationStringId",
      key: "reservationStringId",
      align: "left",
      render: (text, record) => (
        <div>
          {reservationLoading?.id == record?.reservationId &&
          reservationLoading.loading ? (
            "Loading..."
          ) : (
            <div style={{ color: "#008cba", display: "inline" }}>#{text}</div>
          )}
        </div>
      ),
    },
    {
      title: (
        <div className="filterContainer">
          STATUS
          {type != "cancelled" && (
            <FilterFilled
              style={{ color: vendorFilter ? "#bd025d" : "#5A5F7D" }}
              onClick={() => {
                setVendorSearchVisible(!vendorSearchVisible);
              }}
            />
          )}
        </div>
      ),
      dataIndex: "status",
      key: "status",
      align: "left",
      render: (text, value) => (
        <p
          style={{
            backgroundColor: value.isCancelled
              ? "#ff4d4f10"
              : text == "cleaning"
              ? "#fa8b0c10"
              : text == "checkout"
              ? "#ff4d4f10"
              : text == "checkin"
              ? "#20c99710"
              : "rgb(212, 243, 255)",
            borderRadius: "15px",
            color: value.isCancelled
              ? "#ff4d4f"
              : text == "cleaning"
              ? "#fa8b0c"
              : text == "checkout"
              ? "#ff4d4f"
              : text == "checkin"
              ? "#20c997"
              : "rgb(24, 101, 132)",
            display: "inline",
            fontSize: "13px",
            fontWeight: 500,
            padding: "4.5px 11.85px",
          }}
        >
          {value.isCancelled
            ? "Cancelled"
            : text == "cleaning"
            ? "Dirty"
            : text == "checkout"
            ? "Checkout"
            : text == "checkin"
            ? "Checkin"
            : "Confirmed"}
        </p>
      ),
    },
    {
      title: "ROOM TYPE",
      dataIndex: "room_type",
      key: "room_type",
      render(text, record) {
        return {
          children: <div>{text?.name}</div>,
        };
      },
    },
    {
      title: "RATE PLAN",
      dataIndex: "rate_plan",
      key: "rate_plan",
      align: "left",
      render(text, record) {
        return {
          children: <div>{text?.rate_name}</div>,
        };
      },
    },
    {
      title: "ROOM NUMBER",
      dataIndex: "room_name",
      key: "room_name",
      align: "left",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "NUMBER OF GUESTS",
      dataIndex: "guest",
      key: "guest",
      align: "left",
      render: (text) => <span>{text?.adults}</span>,
    },

    {
      title: "SOURCE",
      dataIndex: "general",
      key: "general",
      align: "left",
      render: (text) => <span>{text?.source}</span>,
    },
    {
      title: "CHANNEL",
      dataIndex: "general",
      key: "general",
      align: "left",
      render: (text) => <span>{text?.channel}</span>,
    },
    {
      title: "CHECK-IN",
      dataIndex: "reservationInDateAndTime",
      key: "reservationInDateAndTime",
      align: "left",
      render: (last_seen, record) => (
        <span>{commonFunction.convertToDate(last_seen, "MMM DD, Y")}</span>
      ),
    },
    {
      title: "CHECK-OUT",
      dataIndex: "reservationOutDateAndTime",
      key: "reservationOutDateAndTime",
      align: "left",
      render: (last_seen, record) => (
        <span>
          {last_seen == "-"
            ? last_seen
            : commonFunction.convertToDate(last_seen, "MMM DD, Y")}
        </span>
      ),
    },
    {
      title: "NUMBER OF NIGHTS",
      dataIndex: "totalNights",
      key: "totalNights",
      align: "left",
      render: (text, record) => (
        <span>
          {moment(record.reservationOutDateAndTime).diff(
            moment(record.reservationInDateAndTime),
            "days"
          )}{" "}
          night(s)
        </span>
      ),
    },
    {
      title: "ROOM CHARGES",
      dataIndex: "calculatedPriceWithoutTax",
      key: "calculatedPriceWithoutTax",
      align: "left",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "TAX",
      dataIndex: "calCulatedTaxPrice",
      key: "calCulatedTaxPrice",
      align: "left",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "EXTRAS",
      dataIndex: "payment",
      key: "payment",
      align: "left",
      render: (text) => <span>-</span>,
    },
    {
      title: "TOTAL",
      dataIndex: "calculatedPriceWithTax",
      key: "calculatedPriceWithTax",
      align: "left",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "BOOKED ON",
      dataIndex: "reservationBookTime",
      key: "reservationBookTime",
      align: "left",
      render: (text) => (
        <span>{commonFunction.convertToDate(text, "MMM DD, Y, h:mm A")}</span>
      ),
    },
    {
      title: "BOOKED BY",
      dataIndex: "reservationCreatedStaffUserId",
      key: "reservationCreatedStaffUserId",
      align: "left",
      render: (text) => <span>{text?.username}</span>,
    },
  ];
  const [reservationLoading, setReservationLoading] = useState({
    id: "",
    loading: false,
  });
  const handleReservation = async (id, roomId) => {
    if (reservationLoading.loading == false) {
      setReservationLoading({
        id: id,
        loading: true,
      });
      let response = await dispatch(getSingleReservation(id));
      if (response && response.data) {
        if (roomId) {
          response.data["RoomId"] = roomId;
        }
        history.push("/reservation", { reservationData: response.data });
        setReservationLoading({
          id: "",
          loading: false,
        });
      }
    }
  };
  const handleReset = async (type) => {
    setResetLoader(true);
    if (type == "vendor") {
      delete filterObj.status;
      setFilterObj({ ...filterObj });
      vendorSearchForm.resetFields();
      setVendorFilter(false);
      setVendorSearchVisible(false);
    }
  };
  const handleSearchFilter1 = async () => {
    vendorSearchForm.validateFields().then(async (value) => {
      setVendorFilter(true);
      setFilterObj({
        ...filterObj,
        status: value.search_vendor,
      });
    });
  };
  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div className="table_titles">
                  <h2>{type == "cancelled" ? "Cancel" : "Room"} Reservation</h2>
                </div>
                <div
                  style={{ boxShadow: "none", marginLeft: "10px" }}
                  className="search_lrm"
                >
                  <Search
                    className="receipts-search"
                    placeholder="Search by Reference Number"
                    enterButton
                    onSearch={(e) => onSearch(e)}
                    suffix={<SearchOutlined style={{ fontSize: "20px" }} />}
                    allowClear={{
                      clearIcon: <CloseOutlined />,
                    }}
                  />
                </div>
              </>
            }
            buttons={[
              <div key="1" className="page-header-actions custom_action">
                <div className="actionBtn">
                  <Tooltip
                    title={
                      <p
                        style={{
                          textAlign: "center",
                          marginTop: "10px",
                          marginLeft: "10px",
                        }}
                      >
                        Showing master from{" "}
                        {startDate
                          ? moment(startDate).format("MMMM Do YYYY")
                          : moment()
                              .startOf("month")
                              .format("MMMM Do YYYY")}
                        {" to"}{" "}
                        {endDate
                          ? moment(endDate).format("MMMM Do YYYY")
                          : moment()
                              .endOf("month")
                              .format("MMMM Do YYYY")}
                      </p>
                    }
                  >
                    <ExclamationCircleOutlined
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        fontSize: "18px",
                      }}
                    />
                  </Tooltip>
                  <CalendarButtonPageHeader key="6" type="room-reservattion" />

                  <Popover
                    placement="bottomLeft"
                    content={content}
                    trigger="click"
                  >
                    <Button size="middle" type="white">
                      <FeatherIcon icon="download" size={14} />
                      Export
                    </Button>
                  </Popover>
                </div>
              </div>,
            ]}
          />
        </CardToolbox>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        locale={locale}
                        rowKey="_id"
                        size="small"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 800 }}
                        pagination={false}
                      />
                    ) : (
                      <Table
                        rowKey="_id"
                        size="small"
                        dataSource={roomReservation}
                        columns={columns}
                        fixed={true}
                        onRow={(row) => ({
                          onClick: () => {
                            if (row.roomId) {
                              handleReservation(row.reservationId, row.roomId);
                            } else {
                              handleReservation(row.reservationId);
                            }
                          },
                        })}
                        scroll={{ x: 2500 }}
                        pagination={false}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50px",
                  }}
                  onClick={() => {
                    setChangePage(changePage - 1);
                    changePageData(changePage - 1, 10);
                  }}
                  disabled={changePage == 1 ? true : false}
                >
                  <FeatherIcon
                    size={20}
                    icon="chevron-left"
                    style={{ position: "relative", left: "-11px", top: "2px" }}
                  />
                </Button>
                <Button
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50px",
                    marginLeft: "40px",
                  }}
                  disabled={roomReservation?.length < 10 ? true : false}
                  onClick={() => {
                    setChangePage(changePage + 1);
                    changePageData(changePage + 1, 10);
                  }}
                >
                  <FeatherIcon
                    size={20}
                    icon="chevron-right"
                    style={{ position: "relative", left: "-9px", top: "2px" }}
                  />
                </Button>
              </p>
            </Cards>
          </Col>
        </Row>
        <Modal
          title="Status Filter"
          visible={vendorSearchVisible}
          onCancel={() => setVendorSearchVisible(false)}
          footer={[
            <Button onClick={() => handleReset("vendor")}>
              {resetLoader ? "Loading.." : "Reset"}
            </Button>,
            <Button onClick={() => handleSearchFilter1()} type="primary">
              {loader ? "Loading.." : "Submit"}
            </Button>,
          ]}
          width={600}
        >
          <div>
            <Form form={vendorSearchForm}>
              <Form.Item name="search_vendor">
                <Select
                  showSearch
                  className="custom_select4545"
                  style={{ margin: "8px 0 0" }}
                  placeholder="Select Status"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Select.Option key={1} value={"Confirmed"}>
                    Confirmed
                  </Select.Option>
                  <Select.Option key={2} value={"Dirty"}>
                    Dirty
                  </Select.Option>
                  <Select.Option key={3} value={"Checkout"}>
                    Checkout
                  </Select.Option>
                  <Select.Option key={4} value={"Checkin"}>
                    Checkin
                  </Select.Option>
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
        <Modal
          title="Export Room Reservation"
          visible={modalVisible}
          onOk={form.submit}
          okText={loading ? "Loading..." : "Save"}
          onCancel={handleCancel}
          width={600}
        >
          <Form form={form} name="export_customer" onFinish={onSubmit}>
            <div className="add-product-block">
              <div className="add-product-content">
                <Form.Item
                  initialValue="reservationBookTime"
                  label="Filter by "
                  name="filter_by"
                >
                  <Select
                    name="report"
                    style={{ width: "100%", marginBottom: "10px" }}
                    onChange={(value) => setDateRange(value)}
                  >
                    <Option value="reservationBookTime">Created Date</Option>
                    <Option value="checkInDateAndTime">Check In Date</Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  initialValue="today"
                  label="Date Range"
                  name="dateRange"
                >
                  <Select
                    name="report"
                    style={{ width: "100%", marginBottom: "10px" }}
                    onChange={(value) => setDateRange(value)}
                  >
                    <Option value="today">Today </Option>
                    <Option value="yesterday">Yesterday</Option>
                    <Option value="this_month">This Month</Option>
                    <Option value="last_month">Last Month</Option>
                    <Option value="custom">Custom selection</Option>
                  </Select>
                </Form.Item>
                {DateRanged === "custom" ? (
                  <div>
                    <Form.Item
                      label="Start Date"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                    >
                      <DatePicker
                        style={{ height: "35px" }}
                        placeholder="dd-mm-yyyy"
                        format="YYYY-MM-DD"
                        onChange={(date, datestring) =>
                          setstartdate(datestring)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="End Date"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                    >
                      <DatePicker
                        onChange={(date, datestring) => setenddate(datestring)}
                        style={{ height: "35px" }}
                        placeholder="dd-mm-yyyy"
                        format="YYYY-MM-DD"
                      />
                    </Form.Item>
                  </div>
                ) : (
                  ""
                )}
                <Form.Item
                  name="email"
                  label="Send to Email Address"
                  initialValue={email}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                    { type: "email", message: "A valid email is required" },
                  ]}
                >
                  <Input placeholder="Report will be send to this email" />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default RoomReservation;
