import React, { useState, useRef, useEffect } from "react";
import { Card, Row, Col, Input, message, Skeleton, Modal, Tooltip } from "antd";
import { NavLink } from "react-router-dom";
import { SellModuleNav } from "../Style";
import {
  addOrUpdateRoom,
  getAllRoomList,
} from "../../../redux/room/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { CurrentRoom } from "./CurrentRoom";
import { getReservationList } from "../../../redux/reservation/actionCreator";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const ShowRoom = (props) => {
  let { handdleCurrentRoom } = props;
  const dispatch = useDispatch();
  const [currentStatus, setStatus] = useState("ALL");
  const [modalShowRoomVisible, setModalShowRoom] = useState(false);
  const [roomListData, setRoomListData] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [loader, setLoader] = useState(true);
  const [searchText, setSearhRooms] = useState("");
  const [allRoomList, setAllRoomList] = useState([]);
  const [oneRoomDetials, setRoomdeatils] = useState({});
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });

  const setCategory = (type) => {
    setStatus(type);
  };
  async function fetchRoomList(check) {
    if (!check) {
      setLoader(true);
    }
    let getReservationListData = await dispatch(
      getReservationList(currentRegisterData._id, "room")
    );
    if (
      getReservationListData?.error == false &&
      getReservationListData?.data
    ) {
      let { data } = getReservationListData;
      let roomObj = {};
      data.reverse().map((k) => {
        k.roomInfo.map((o) => {
          let startDate = moment();
          if (
            o.status != "checkout" &&
            (startDate.isSame(o.reservationInDateAndTime, "day") ||
              startDate.isSame(o.reservationOutDateAndTime, "day") ||
              startDate.isBetween(
                o.reservationInDateAndTime,
                o.reservationOutDateAndTime,
                "day"
              ))
          ) {
            if (roomObj[o.roomId]) {
              if (roomObj[o.roomId].status == "checkin") {
                roomObj[o.roomId] = {
                  ...roomObj[o.roomId],
                };
              } else {
                roomObj[o.roomId] = {
                  reservationObjectId: k,
                  status: o.status,
                  roomInfoId: o._id,
                };
              }
            } else {
              roomObj[o.roomId] = {
                reservationObjectId: k,
                status: o.status,
                roomInfoId: o._id,
              };
            }
          }
        });
      });

      const getRoomList = await dispatch(
        getAllRoomList(currentRegisterData._id)
      );

      if (getRoomList && getRoomList.error == false) {
        setLoader(false);
        let filter = [];
        console.log("getRoomList?.roomList", getRoomList?.roomList);
        getRoomList?.roomList.map((room) => {
          if (
            roomObj[room._id] &&
            (roomObj[room._id].status == "assign" ||
              roomObj[room._id].status == "checkin" ||
              roomObj[room._id].status == "cleaning")
          ) {
            room.reservationObjectId = roomObj[room._id].reservationObjectId;
            (room.status = roomObj[room._id].status),
              (room.roomInfoId = roomObj[room._id].roomInfoId);
          } else {
            room.status =
              room.status == "maintainance" ? "maintainance" : "available";
            room.reservationObjectId = null;
          }
          if (room?.room_type?.tax_group?.taxes) {
            room.room_type.tax_group.taxes = room?.room_type?.tax_group?.taxes?.map(
              (k) => {
                if (
                  room?.room_type?.tax_group?.taxes_inclusive_in_product_price
                ) {
                  k["type"] = "inclusive";
                } else {
                  k["type"] = "exclusive";
                }
                return k;
              }
            );
          }

          let roomType = filter.findIndex(
            (val) => val._id == room?.room_type?._id
          );
          if (roomType == -1) {
            filter.push({
              type: "room_type",
              name: room?.room_type?.name,
              _id: room?.room_type?._id,
            });
          }
          let find = filter.findIndex((val) => val._id == room.location._id);
          if (find == -1) {
            filter.push({
              type: "location",
              name: room?.location?.name,
              _id: room.location._id,
            });
          }
        });

        setFloorList(
          filter.sort((a, b) => {
            if (a.type === "room_type" && b.type === "location") {
              return -1;
            } else if (a.type === "location" && b.type === "room_type") {
              return 1;
            } else {
              return 0;
            }
          })
        );
        console.log("getRoomListroomList", getRoomList?.roomList);
        setRoomListData(getRoomList?.roomList);
        setAllRoomList(getRoomList?.roomList);
      } else {
        message.error({
          content: getRoomList.message,
          style: {
            float: "center",
            marginTop: "2vh",
          },
        });
      }
    }
  }

  useEffect(() => {
    if (currentRegisterData && currentRegisterData?._id) {
      fetchRoomList();
    }
  }, [currentRegisterData]);

  const hanldeFilter = (h) => {
    if (currentStatus == "ALL") {
      return true;
    } else if (
      currentStatus &&
      currentStatus.type == "room_type" &&
      currentStatus._id == h.room_type._id
    ) {
      return true;
    } else if (
      currentStatus &&
      currentStatus.type == "location" &&
      currentStatus._id == h.location._id
    ) {
      return true;
    } else if (currentStatus == "AVAILABLE" && h.status == "available") {
      return true;
    } else if (currentStatus == "CHECKIN" && h.status == "checkin") {
      return true;
    } else if (currentStatus == "DIRTY" && h.status == "cleaning") {
      return true;
    } else if (currentStatus == "BLOCK" && h.status == "maintainance") {
      return true;
    }
  };
  useEffect(() => {
    setRoomListData(
      allRoomList.filter((h) =>
        `${h.room_type.name} ${h.room_name}`
          .toLowerCase()
          .includes(searchText.toLowerCase())
      )
    );
  }, [searchText]);
  console.log("RoomListasdasdadadadaadadad", roomListData);
  return (
    <>
      <Row>
        <Input
          placeholder="Search Rooms"
          style={{
            height: "40px",
            marginTop: "-10px",
            marginBottom: "6px",
          }}
          className="search-none"
          onChange={(e) => setSearhRooms(e.target.value)}
        />
        <Col xxl={3} lg={3} xl={3} xs={9} className="category-col">
          <Card headless className="category-card">
            <SellModuleNav>
              {loader ? (
                <ul lassName="currentbuild-ul">
                  <li style={{ fontSize: 13 }}>
                    <NavLink
                      to="#"
                      onClick={setCategory.bind(this, "ALL")}
                      className={"active"}
                    >
                      <span className="nav-text">
                        <Skeleton.Button
                          active={true}
                          size={"default"}
                          shape={"default"}
                          block={false}
                        />
                      </span>
                    </NavLink>
                  </li>
                  {[...Array(3)].map((val) => {
                    return (
                      <li style={{ fontSize: 13 }}>
                        <NavLink
                          to="#"
                          onClick={setCategory.bind(this, "ALL")}
                          className={"not-active"}
                        >
                          <span className="nav-text">
                            <Skeleton.Button
                              active={true}
                              size={"default"}
                              shape={"default"}
                              block={false}
                            />
                          </span>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <ul className="currentbuild-ul">
                  <li style={{ fontSize: 13 }}>
                    <NavLink
                      to="#"
                      onClick={setCategory.bind(this, "ALL")}
                      className={
                        currentStatus === "ALL" ? "active" : "not-active"
                      }
                    >
                      <span className="nav-text">All</span>
                    </NavLink>
                  </li>
                  <li style={{ fontSize: 13 }}>
                    <NavLink
                      to="#"
                      onClick={setCategory.bind(this, "CHECKIN")}
                      className={
                        currentStatus === "CHECKIN" ? "active" : "not-active"
                      }
                    >
                      <span className="nav-text">In-house</span>
                    </NavLink>
                  </li>
                  <li style={{ fontSize: 13 }}>
                    <NavLink
                      to="#"
                      onClick={setCategory.bind(this, "DIRTY")}
                      className={
                        currentStatus === "DIRTY" ? "active" : "not-active"
                      }
                    >
                      <span className="nav-text">Dirty</span>
                    </NavLink>
                  </li>
                  <li style={{ fontSize: 13 }}>
                    <NavLink
                      to="#"
                      onClick={setCategory.bind(this, "AVAILABLE")}
                      className={
                        currentStatus === "AVAILABLE" ? "active" : "not-active"
                      }
                    >
                      <span className="nav-text">Available</span>
                    </NavLink>
                  </li>
                  <li style={{ fontSize: 13 }}>
                    <NavLink
                      to="#"
                      onClick={setCategory.bind(this, "BLOCK")}
                      className={
                        currentStatus === "BLOCK" ? "active" : "not-active"
                      }
                    >
                      <span className="nav-text">Block</span>
                    </NavLink>
                  </li>
                  {floorList.map((val) => {
                    return (
                      <li style={{ fontSize: 13 }}>
                        <NavLink
                          to="#"
                          onClick={setCategory.bind(this, val)}
                          className={
                            currentStatus?.name === val.name
                              ? "active"
                              : "not-active"
                          }
                        >
                          <span className="nav-text">
                            <span>{val.name}</span>
                          </span>
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              )}
            </SellModuleNav>
          </Card>
        </Col>
        <Col xxl={21} lg={21} xl={21} xs={15}>
          <Card
            headless
            size="large"
            className="order-card"
            style={{ backgroundColor: "transparent" }}
          >
            <div className="sell-table-parent delivery-parent list-boxmain">
              {loader ? (
                <Row>
                  {Array.from({ length: 35 }).map((val, index) => (
                    <>
                      <Col
                        xxl={4}
                        lg={4}
                        xl={4}
                        sm={12}
                        xs={24}
                        className="sell-table-col"
                        key={index}
                      >
                        <div className={"loader-empty"}>
                          <div>
                            <Skeleton.Input
                              active={true}
                              size={"default"}
                              block={false}
                              style={{ height: "100px" }}
                            />
                          </div>
                        </div>
                      </Col>
                    </>
                  ))}
                </Row>
              ) : (
                <div>
                  <Row gutter={[12, 12]} className="delivery-row list-box-row">
                    {roomListData.filter(hanldeFilter).map((val, index) => {
                      let { status } = val;
                      return (
                        <Col
                          xxl={4}
                          lg={4}
                          xl={4}
                          sm={12}
                          xs={24}
                          key={23}
                          className="sell-table-col"
                          onClick={() => {
                            setModalShowRoom(true);
                            setRoomdeatils(val);
                          }}
                        >
                          <div
                            className={
                              status == "assign" || status == "checkin"
                                ? "sell-main-order"
                                : status == "cleaning"
                                ? "sell-unpaid"
                                : "sell-empty"
                            }
                            style={{ borderBottom: "none" }}
                          >
                            <div
                              className="sell-table-counter"
                              style={{
                                padding: "10px 20px",
                                backgroundColor:
                                  status == "assign" || status == "checkin"
                                    ? "#ade9ad"
                                    : status == "cleaning"
                                    ? "#fce089"
                                    : status == "maintainance"
                                    ? "rgb(249,124,124)"
                                    : "#F4F5F7",
                                borderRadius: "4px",
                                width: "fit-content",
                              }}
                            >
                              <div className="counter_served">
                                {`${val.room_type.name} ${val.room_name}`}
                              </div>

                              {val?.housekeeper?.name && (
                                <div
                                  style={{
                                    fontSize: "12px",
                                  }}
                                >
                                  {val?.housekeeper?.name}
                                  {val.remark != "" && (
                                    <Tooltip title={<div>{val.remark}</div>}>
                                      <ExclamationCircleOutlined
                                        style={{
                                          cursor: "pointer",
                                          marginLeft: "4px",
                                          paddingTop: "4px",
                                        }}
                                      />
                                    </Tooltip>
                                  )}
                                </div>
                              )}
                              {val.remark &&
                                val.remark != "" &&
                                !val?.housekeeper?.name && (
                                  <Tooltip
                                    title={<div>{val.remark}</div>}
                                    // cursor={{fill:"#bd025d"}}
                                  >
                                    <ExclamationCircleOutlined
                                      style={{
                                        cursor: "pointer",
                                      }}
                                    />
                                  </Tooltip>
                                )}
                            </div>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </div>
              )}
            </div>
          </Card>
        </Col>
      </Row>

      {modalShowRoomVisible && (
        <CurrentRoom
          oneRoomDetials={oneRoomDetials}
          modalShowRoomVisible={modalShowRoomVisible}
          setModalShowRoom={setModalShowRoom}
          fetchRoomList={fetchRoomList}
          currentRegisterData={currentRegisterData}
        />
      )}
    </>
  );
};

export { ShowRoom };
