import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Tabs,
  Form,
  Input,
  Tag,
  Row,
  Radio,
  Button,
  message,
  Col,
  Select,
  DatePicker,
  Spin,
} from "antd";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import moment from "moment";
import { getItem } from "../../../utility/localStorageControl";
import { getAllRateDateWise } from "../../../redux/roomLocation/actionCreator";
import { checkRoomAvailblity } from "../../../redux/roomType/actionCreator";

const { TabPane } = Tabs;
const AddAddtionalRoom = (props) => {
  let {
    addRoomModalVisible,
    setAddRoomModalVisible,
    roomTypeList,
    handleAddAddtionalRoom,
    loader,
    rsSymbol,
    reservationData,
    currentRegisterData,
  } = props;
  let userDetails = getItem("userDetails");
  const handleCancel = () => {
    setAddRoomModalVisible(false);
  };
  const dispatch = useDispatch();
  const [roomTypeForm] = Form.useForm();
  let [selectedRoomtype, setSelectedRoomType] = useState([
    {
      room_type: {
        _id: "",
        name: "",
        default_adult: 0,
        default_children: 0,
        default_infant: 0,
        tax_group: {},
        rate_plan_list: [],
        numberOfRooms: [],
        firstTimeSelect: false,
      },
      rate_plan: {},
      numberOfRooms: 0,
      rooms: [],
      total_guest: 1,
      calculatedPriceWithoutTax: 0,
      calCulatedTaxPrice: 0,
      calculatedPriceWithTax: 0,
      roomTypeId: "",
      isManualPricing: false,
    },
  ]);
  const [totalCalculatedTax, setTotalCalculatedtax] = useState(0);
  const [totalCalculatedPrice, setTotalCalculatedPrice] = useState(0);
  const [
    totalCalculatedPriceWithoutTax,
    setTotalCalculatedPriceWihoutTax,
  ] = useState(0);
  const [rateDateWiseObj, setRateDatewiseRateObj] = useState({});
  const [reservationStartDate, setReservationStartDate] = useState(
    moment(reservationData?.reservationInDateAndTime).format("ddd DD MMM YYYY")
  );
  const [reservationEndDate, setReservationEndDate] = useState(
    moment(reservationData?.reservationOutDateAndTime).format("ddd DD MMM YYYY")
  );
  const [numberOfNights, setNumberOfNights] = useState(
    moment(reservationEndDate).diff(moment(reservationStartDate), "days")
  );
  const [reservationDisable, setReservationDisable] = useState(false);
  const [rateLoader, setRateLoader] = useState(false);
  const fetchDateWiseRate = async () => {
    setRateLoader(true);
    let startDate = moment(reservationStartDate).format("MM/DD/YYYY");
    let endDate = moment(reservationEndDate).format("MM/DD/YYYY");
    const getRateList = await dispatch(
      getAllRateDateWise(currentRegisterData?._id, {
        startDate: startDate,
        endDate: endDate,
      })
    );
    if (getRateList && getRateList.data) {
      let obj = {};
      getRateList.data.map((k) => {
        obj[`${moment(k.date).format("MM/DD/YYYY")}-${k.rate_id}`] = k;
      });
      setRateDatewiseRateObj(obj);
      setRateLoader(false);
    }
  };
  useEffect(() => {
    if (
      getItem("rate_app_enable") &&
      currentRegisterData &&
      currentRegisterData._id
    ) {
      fetchDateWiseRate();
    }
  }, [currentRegisterData?._id, reservationStartDate, reservationEndDate]);
  function disabledEndDate(current) {
    return current && current <= moment(reservationStartDate);
  }

  const onSelectRoomType = (roomType, index, firstTimeSelect) => {
    if (firstTimeSelect == false) {
      selectedRoomtype[index]["room_type"] = {
        _id: roomType._id,
        name: roomType.name,
        default_adult: roomType.default_adult,
        default_children: roomType.default_children,
        default_infant: roomType.default_infant,
        tax_group: roomType.tax_group,
        rate_plan_list: roomType.rate_plan,
        numberOfRooms: [...Array(roomType.numberOfRooms)],
        firstTimeSelect: true,
        max_adult: roomType.max_adult,
        max_children: roomType.max_children,
        max_infant: roomType.max_infant,
      };
      selectedRoomtype[index]["roomTypeId"] = roomType._id;
      if (
        currentRegisterData?.connect_channel &&
        currentRegisterData?.channel_key != "" &&
        roomType?.channel_room_code != ""
      ) {
        selectedRoomtype[index]["room_type"]["channel_room_code"] =
          roomType?.channel_room_code;
        selectedRoomtype[index]["room_type"]["channel_room_name"] =
          roomType?.channel_room_name;
      }
      setSelectedRoomType([...selectedRoomtype]);
    } else {
      selectedRoomtype[index]["room_type"] = {
        _id: roomType._id,
        name: roomType.name,
        default_adult: roomType.default_adult,
        default_children: roomType.default_children,
        default_infant: roomType.default_infant,
        tax_group: roomType.tax_group,
        rate_plan_list: roomType.rate_plan,
        numberOfRooms: [...Array(roomType.numberOfRooms)],
        firstTimeSelect: true,
        max_adult: roomType.max_adult,
        max_children: roomType.max_children,
        max_infant: roomType.max_infant,
      };
      selectedRoomtype[index]["roomTypeId"] = roomType._id;
      selectedRoomtype[index].rate_plan = {};
      selectedRoomtype[index].numberOfRooms = 0;
      selectedRoomtype[index].rooms = [];
      selectedRoomtype[index].total_guest = 0;
      selectedRoomtype[index].calculatedPriceWithoutTax = 0;
      selectedRoomtype[index].calCulatedTaxPrice = 0;
      selectedRoomtype[index].calculatedPriceWithTax = 0;
      selectedRoomtype[index].roomTypeId = "";
      selectedRoomtype[index].isManualPricing = false;

      if (
        currentRegisterData?.connect_channel &&
        currentRegisterData?.channel_key != "" &&
        roomType?.channel_room_code != ""
      ) {
        selectedRoomtype[index]["room_type"]["channel_room_code"] =
          roomType?.channel_room_code;
        selectedRoomtype[index]["room_type"]["channel_room_name"] =
          roomType?.channel_room_name;
      }
      let obj = {};
      obj[`rate_plan_${index}`] = undefined;
      obj[`no_rooms_${index}`] = undefined;
      roomTypeForm.setFieldsValue(obj);
      setSelectedRoomType([...selectedRoomtype]);
    }
  };

  const onSelectRatePlan = (ratePlan, index) => {
    let default_adult_rate = 0;
    let extra_adult_rate = 0;
    let extra_child_rate = 0;
    let extra_infant_rate = 0;
    const currentDate = new Date(reservationStartDate);
    const endDate = new Date(reservationEndDate);

    ratePlan?.adult_rates?.map((j, i) => {
      let myRate = 0;
      if (i) {
        const currentDate1 = new Date(reservationStartDate);
        const endDate1 = new Date(reservationEndDate);
        while (currentDate1 < endDate1) {
          let thatDay = currentDate1.toLocaleDateString("en-US", {
            month: "2-digit",
            day: "2-digit",
            year: "numeric",
          });

          if (
            rateDateWiseObj &&
            ratePlan &&
            ratePlan.rate_id &&
            rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
          ) {
            let latestObj =
              rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
            if (latestObj[`default_adult${j.numberOfAdult}`]) {
              myRate += Number(latestObj[`default_adult${j.numberOfAdult}`]);
            } else {
              myRate += Number(j.rate);
            }
          } else {
            myRate += Number(j.rate);
          }

          currentDate1.setDate(currentDate1.getDate() + 1);
        }
        j.rate = (myRate / numberOfNights).toString();
      }
      return j;
    });
    while (currentDate < endDate) {
      let thatDay = currentDate.toLocaleDateString("en-US", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      });

      if (
        rateDateWiseObj &&
        ratePlan &&
        ratePlan.rate_id &&
        rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]
      ) {
        let latestObj =
          rateDateWiseObj[`${thatDay}-${ratePlan.rate_id}`]?.value;
        default_adult_rate += Number(
          latestObj.default_adult_rate != undefined
            ? latestObj.default_adult_rate
            : ratePlan?.default_adult_rate
        );
        extra_adult_rate += Number(
          latestObj.extra_adult_rate != undefined
            ? latestObj.extra_adult_rate
            : ratePlan.extra_adult_rate
        );
        extra_child_rate += Number(
          latestObj.extra_child_rate != undefined
            ? latestObj.extra_child_rate
            : ratePlan?.extra_child_rate
        );
        extra_infant_rate += Number(
          latestObj.extra_infant_rate != undefined
            ? latestObj.extra_infant_rate
            : ratePlan?.extra_infant_rate
        );
      } else {
        default_adult_rate += Number(ratePlan.default_adult_rate);
        extra_adult_rate += Number(ratePlan.extra_adult_rate);
        extra_child_rate += Number(ratePlan.extra_child_rate);
        extra_infant_rate += Number(ratePlan.extra_infant_rate);
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }

    ratePlan = {
      ...ratePlan,
      default_adult_rate: (default_adult_rate / numberOfNights).toString(),
      extra_adult_rate: (extra_adult_rate / numberOfNights).toString(),
      extra_child_rate: (extra_child_rate / numberOfNights).toString(),
      extra_infant_rate: (extra_infant_rate / numberOfNights).toString(),
    };

    selectedRoomtype[index]["rate_plan"] = ratePlan;
    setSelectedRoomType([...selectedRoomtype]);
  };

  function createArrayOfObjects(dataCount) {
    const arrayOfObjects = [];
    for (let i = 1; i <= dataCount; i++) {
      const object = {
        adults: 1,
        children: 0,
        infants: 0,
      };
      arrayOfObjects.push(object);
    }
    return arrayOfObjects;
  }

  function removeArrayOfObjects(originalArray, x) {
    const newArray = originalArray.slice(0, x);
    return newArray;
  }

  const handleArray = (x, array) => {
    if (array.length < x) {
      let checkPush = x - array.length;
      let newArray = createArrayOfObjects(checkPush);
      array = [...array, ...newArray];
      return array;
    } else if (array.length > x) {
      return removeArrayOfObjects(array, x);
    }
  };

  const onSelectRoomNo = (no, index, array) => {
    selectedRoomtype[index]["rooms"] = handleArray(no, array);
    selectedRoomtype[index]["numberOfRooms"] = no;
    checkRoomAvailble(
      reservationStartDate,
      reservationEndDate,
      no,
      selectedRoomtype[index]?.room_type
    );
    setSelectedRoomType([...selectedRoomtype]);
  };

  const updateGuest = (roomTypeIndex, roomIndex, guestType, checkPlusMinus) => {
    if (checkPlusMinus == "plus") {
      let updateNumber =
        1 + selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];

      if (
        guestType == "adults" &&
        updateNumber > selectedRoomtype[roomTypeIndex]?.room_type?.max_adult
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }
      if (
        guestType == "children" &&
        updateNumber > selectedRoomtype[roomTypeIndex]?.room_type?.max_children
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }
      if (
        guestType == "infants" &&
        updateNumber > selectedRoomtype[roomTypeIndex]?.room_type?.max_infant
      ) {
        message.error("Guest limit exceeded.");
        return true;
      }

      selectedRoomtype[roomTypeIndex].rooms[roomIndex][
        guestType
      ] = ++selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];
      setSelectedRoomType([...selectedRoomtype]);
      return selectedRoomtype;
    } else {
      if (selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType] > 0) {
        selectedRoomtype[roomTypeIndex].rooms[roomIndex][
          guestType
        ] = --selectedRoomtype[roomTypeIndex].rooms[roomIndex][guestType];
        setSelectedRoomType([...selectedRoomtype]);
        return selectedRoomtype;
      } else {
        setSelectedRoomType([...selectedRoomtype]);
        return selectedRoomtype;
      }
    }
  };

  function calculateTaxInclusive(totalAmount, taxRate) {
    taxRate = taxRate / 100;
    var originalAmount = totalAmount / (1 + taxRate);
    var taxAmount = totalAmount - originalAmount;
    return {
      originalAmount: originalAmount,
      taxAmount: taxAmount,
    };
  }
  const calCulatedTaxPriceofThisRoomType = (
    totalPrice,
    taxes,
    checkIsInclusive
  ) => {
    let totalTax = 0;
    let inclusiveTax = 0;
    let totalTaxPercent = 0;
    taxes?.map((val) => {
      if (checkIsInclusive != "remove") {
        totalTaxPercent += Number(val.tax_percentage);
      }
    });
    if (checkIsInclusive == true) {
      totalTax = calculateTaxInclusive(totalPrice, totalTaxPercent).taxAmount;
      inclusiveTax = totalTax;
    } else if (checkIsInclusive == false) {
      totalTax = (totalPrice * totalTaxPercent) / 100;
    }
    inclusiveTax = parseFloat(inclusiveTax.toFixed(2));
    totalTax = parseFloat(totalTax.toFixed(2));
    return { totalTax, inclusiveTax };
  };

  const calculatedPriceOfThisRoomType = (selectRoomType) => {
    if (selectRoomType.room_type.name != "") {
      let totalPrice = 0;
      let totalTaxPrice = 0;
      let totalGuest = 0;

      selectRoomType.rooms?.map((val) => {
        // adult calculated
        let oneRoomCalCulatedPrice = 0;
        if (selectRoomType.room_type.default_adult >= val.adults) {
          let default_adult_rate = selectRoomType.rate_plan.default_adult_rate;
          if (selectRoomType.rate_plan?.adult_rates) {
            let checkadultsPrice = selectRoomType.rate_plan?.adult_rates.find(
              (g, i) => i > 0 && g.numberOfAdult == val.adults
            );
            if (checkadultsPrice) {
              default_adult_rate = checkadultsPrice.rate;
            }
          }
          oneRoomCalCulatedPrice =
            numberOfNights *
            (oneRoomCalCulatedPrice + Number(default_adult_rate));
        } else {
          let checkExtraAdult =
            val.adults - selectRoomType.room_type.default_adult;
          oneRoomCalCulatedPrice =
            numberOfNights *
            (oneRoomCalCulatedPrice +
              Number(selectRoomType.rate_plan.default_adult_rate));
          let extraPriceOfAdult =
            numberOfNights *
            (checkExtraAdult *
              Number(selectRoomType.rate_plan.extra_adult_rate));
          oneRoomCalCulatedPrice = oneRoomCalCulatedPrice + extraPriceOfAdult;
        }

        // Extra children calculated
        if (val.children > selectRoomType.room_type.default_children) {
          {
            let checkExtraChildren =
              val.children - selectRoomType.room_type.default_children;

            let extraPriceOfChildren =
              numberOfNights *
              (checkExtraChildren *
                Number(selectRoomType.rate_plan.extra_child_rate));
            oneRoomCalCulatedPrice =
              oneRoomCalCulatedPrice + extraPriceOfChildren;
          }
        }

        // Extra infants calculated
        if (val.infants > selectRoomType.room_type.default_infant) {
          {
            let checkExtraInfants =
              val.infants - selectRoomType.room_type.default_infant;

            let extraPriceOfInfants =
              numberOfNights *
              (checkExtraInfants *
                Number(selectRoomType.rate_plan.extra_infant_rate));
            oneRoomCalCulatedPrice =
              oneRoomCalCulatedPrice + extraPriceOfInfants;
          }
        }
        totalPrice = totalPrice + oneRoomCalCulatedPrice;
        totalGuest = val.adults + val.children + val.infants;
      });

      setTotalCalculatedPriceWihoutTax(totalPrice);

      let { totalTax, inclusiveTax } = calCulatedTaxPriceofThisRoomType(
        totalPrice,
        selectRoomType.room_type.tax_group.taxes,
        selectRoomType.room_type.tax_group.taxes_inclusive_in_product_price
      );
      selectRoomType["calculatedPriceWithoutTax"] = totalPrice - inclusiveTax;
      totalTaxPrice = totalTax;
      selectRoomType["calCulatedTaxPrice"] = totalTaxPrice;
      selectRoomType["calCulatedInclusiveTaxPrice"] = inclusiveTax;
      totalPrice = totalPrice + totalTaxPrice - inclusiveTax;
      selectRoomType["calculatedPriceWithTax"] = totalPrice;
      selectRoomType["total_guest"] = totalGuest;
      setTotalCalculatedPrice(totalPrice);
      setTotalCalculatedtax(totalTaxPrice);
      return selectRoomType;
    }
  };

  useEffect(() => {
    selectedRoomtype?.map((roomType) => {
      if (roomType.room_type.name != "") {
        calculatedPriceOfThisRoomType(roomType);
      }
    });
  }, [selectedRoomtype, numberOfNights]);
  const handleSelectRoomType = (selectedRoomtype) => {
    let array = selectedRoomtype.map((val) => {
      if (val?.room_type?.rate_plan_list?.length) {
        let findIndex = val.room_type.rate_plan_list.findIndex(
          (k) => k.rate_id == val.rate_plan.rate_id
        );
        if (findIndex != -1) {
          val.room_type.rate_plan_list[findIndex] = val.rate_plan;
        }
        return val;
      } else {
        return val;
      }
    });
    console.log("array456123456123456", array);
    return array;
  };
  const handleSubmitBasicDetails = () => {
    let startObject = new Date(reservationStartDate);
    startObject.setHours(12, 30, 0, 0);
    let endObject = new Date(reservationEndDate);
    endObject.setHours(12, 30, 0, 0);
    selectedRoomtype[0]["reservationInDateAndTime"] = startObject;
    selectedRoomtype[0]["reservationOutDateAndTime"] = endObject;
    selectedRoomtype[0]["reservationBookTime"] = new Date();
    selectedRoomtype[0]["reservationCreatedStaffUserId"] =
      userDetails.restaurant_user_id;
    handleAddAddtionalRoom(handleSelectRoomType(selectedRoomtype));
  };

  const handleTaxEdit = (value) => {
    let tax_group = selectedRoomtype[0]["room_type"]["tax_group"];
    if (tax_group?.taxes.length) {
      tax_group.taxes_inclusive_in_product_price = value;
      selectedRoomtype[0]["room_type"]["tax_group"] = tax_group;
      setSelectedRoomType([...selectedRoomtype]);
    }
  };

  const checkRoomAvailble = async (
    reservationStartDate,
    reservationEndDate,
    room,
    roomTypeData
  ) => {
    let startDate = moment(reservationStartDate).format("YYYY-MM-DD");
    let endDate = moment(reservationEndDate)
      .subtract(1, "days")
      .format("YYYY-MM-DD");

    let response = await dispatch(
      checkRoomAvailblity({
        reservationStartDate: startDate,
        reservationEndDate: endDate,
        roomTypeId: roomTypeData?._id,
        needRoom: room,
        totalRoom: roomTypeData?.numberOfRooms?.length,
      })
    );

    if (response && response.data) {
      if (response.data.avilableStatus == false) {
        message.error(
          `Selected rooms are currently occupied for this category. Please wait for availability or consider offering alternative category.`
        );
        setReservationDisable(true);
      } else {
        setReservationDisable(false);
      }
    }
  };
  return (
    <Modal
      title="Add room"
      open={addRoomModalVisible}
      onCancel={handleCancel}
      onOk={roomTypeForm.submit}
      width={600}
      okButtonProps={{ disabled: reservationDisable }}
      okText={loader ? "Loading..." : "Add"}
    >
      <Form
        autoComplete="off"
        size="large"
        onFinish={handleSubmitBasicDetails}
        form={roomTypeForm}
      >
        <Row className="stayBox">
          <Col md={12} sm={12} lg={12} xxl={12} xs={24} className="gutter-row">
            <Form.Item
              name="stay_start_date"
              label="Stay Start Date"
              style={{
                marginRight: "10px",
              }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={moment(reservationStartDate, "ddd DD MMM YYYY")}
                size="large"
                // disabledDate={disabledStartDate}
                onChange={(date, string) => {
                  let nights = moment(reservationEndDate).diff(
                    moment(string),
                    "days"
                  );
                  setReservationStartDate(string);
                  let outDate = reservationEndDate;
                  if (nights <= 0) {
                    outDate = moment(string)
                      .add(1, "days")
                      .format("ddd DD MMM YYYY");
                    roomTypeForm.setFieldsValue({
                      stay_end_date: moment(outDate, "ddd DD MMM YYYY"),
                    });
                    setReservationEndDate(outDate);
                    setNumberOfNights(
                      moment(outDate).diff(moment(string), "days")
                    );
                  } else {
                    setNumberOfNights(
                      moment(reservationEndDate).diff(moment(string), "days")
                    );
                  }

                  if (selectedRoomtype[0]?.numberOfRooms) {
                    checkRoomAvailble(
                      string,
                      outDate,
                      selectedRoomtype[0]?.numberOfRooms,
                      selectedRoomtype[0]?.room_type
                    );
                  }
                }}
                format="ddd DD MMM YYYY"
              />
            </Form.Item>
          </Col>
          <Col md={12} sm={12} lg={12} xxl={12} xs={24}>
            <Form.Item
              name="stay_end_date"
              label="Stay End Date"
              style={{
                marginRight: "10px",
              }}
            >
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={moment(reservationEndDate, "ddd DD MMM YYYY")}
                size="large"
                allowClear={false}
                disabledDate={disabledEndDate}
                onChange={(date, string) => {
                  setNumberOfNights(
                    moment(string).diff(moment(reservationStartDate), "days")
                  );
                  setReservationEndDate(string);
                  if (selectedRoomtype[0]?.numberOfRooms) {
                    checkRoomAvailble(
                      reservationStartDate,
                      string,
                      selectedRoomtype[0]?.numberOfRooms,
                      selectedRoomtype[0]?.room_type
                    );
                  }
                }}
                format="ddd DD MMM YYYY"
              />
            </Form.Item>
          </Col>
        </Row>
        {rateLoader ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Spin />
          </div>
        ) : (
          <div>
            {selectedRoomtype?.map((val, index) => {
              return (
                <div style={{ padding: "5px" }}>
                  <Row>
                    <Col
                      xxl={8}
                      xl={8}
                      lg={8}
                      md={8}
                      sm={8}
                      xs={24}
                      className="gutter-box"
                    >
                      <Form.Item
                        label="Room Type"
                        rules={[
                          {
                            required: true,
                            message: "Room type is Required",
                          },
                        ]}
                      >
                        <Select
                          className="high_addpd room_reservation"
                          showSearch
                          filterOption={(input, option) =>
                            option.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select room type"
                          onChange={(l) => {
                            let roomType = JSON.parse(l);
                            onSelectRoomType(
                              roomType,
                              index,
                              val.room_type.firstTimeSelect
                            );
                          }}
                        >
                          {roomTypeList?.map((data) => (
                            <Select.Option
                              key={data._id}
                              value={JSON.stringify(data)}
                            >
                              {data?.name}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={8}
                      xl={8}
                      lg={8}
                      md={8}
                      sm={8}
                      xs={24}
                      className="gutter-box"
                    >
                      <Form.Item
                        className="custom-input-text"
                        label="Plan"
                        name={`rate_plan_${index}`}
                        rules={[
                          {
                            required: true,
                            message: "plan type is Required",
                          },
                        ]}
                      >
                        <Select
                          className="high_addpd room_reservation"
                          showSearch
                          filterOption={(input, option) =>
                            option.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                          placeholder="Select plan type"
                          onChange={(val) => {
                            let ratePlan = JSON.parse(val);
                            onSelectRatePlan(ratePlan, index);
                          }}
                          dropdownRender={(menu) => <div>{menu}</div>}
                        >
                          {val.room_type.rate_plan_list &&
                            val.room_type.rate_plan_list?.map((data) => (
                              <Select.Option
                                key={data._id}
                                value={JSON.stringify(data)}
                              >
                                {data?.rate_name}
                              </Select.Option>
                            ))}
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col
                      xxl={8}
                      xl={8}
                      lg={8}
                      md={8}
                      sm={8}
                      xs={24}
                      className="gutter-box"
                    >
                      <Form.Item
                        className="custom-input-text"
                        label="Number of Rooms"
                        name={`no_rooms_${index}`}
                        rules={[
                          {
                            required: true,
                            message: "Numbers of room is Required.",
                          },
                        ]}
                      >
                        <Select
                          className="high_addpd room_reservation"
                          showSearch
                          filterOption={(input, option) =>
                            option.value
                              .toString()
                              .toLowerCase()
                              .indexOf(input.toString().toLowerCase()) >= 0
                          }
                          placeholder="Select No. of rooms"
                          dropdownRender={(menu) => <div>{menu}</div>}
                          onChange={(j) => onSelectRoomNo(j, index, val.rooms)}
                        >
                          {val.room_type.numberOfRooms?.map((data, i) => (
                            <Select.Option key={i + 1} value={i + 1}>
                              {i + 1}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  {val.rooms.length ? (
                    <p>
                      <small>
                        This room type can occupy {val.room_type.default_adult}{" "}
                        x adults, {val.room_type.default_children} x children,{" "}
                        {val.room_type.default_infant} x infants{" "}
                      </small>
                    </p>
                  ) : (
                    ""
                  )}
                  {val.rooms.length > 0
                    ? val.rooms?.map((r, i) => {
                        return (
                          <>
                            <div className="roomNumber_info">
                              <p
                                style={{
                                  marginLeft: "10px",
                                  marginBottom: "7px",
                                  fontSize: "15px",
                                }}
                              >
                                <strong>
                                  Room {i + 1}{" "}
                                  {`(${val?.rate_plan?.default_adult_rate})`}
                                </strong>
                              </p>
                              <div className="guest_numbersBox">
                                <div className="guestCounter">
                                  <p
                                    style={{
                                      marginLeft: "10px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Adults
                                  </p>
                                  <div className="quantityies qucuentlft">
                                    <span
                                      className="qunatity-adjust"
                                      onClick={() =>
                                        updateGuest(index, i, "adults", "minus")
                                      }
                                    >
                                      −
                                    </span>
                                    {r.adults}
                                    <span
                                      className="qunatity-adjust"
                                      onClick={() =>
                                        updateGuest(index, i, "adults", "plus")
                                      }
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>
                                <div className="guestCounter">
                                  <p
                                    style={{
                                      marginLeft: "5px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    children
                                  </p>
                                  <div className="quantityies qucuentlft">
                                    <span
                                      className="qunatity-adjust"
                                      onClick={() =>
                                        updateGuest(
                                          index,
                                          i,
                                          "children",
                                          "minus"
                                        )
                                      }
                                    >
                                      −
                                    </span>
                                    {r.children}
                                    <span
                                      className="qunatity-adjust"
                                      onClick={() =>
                                        updateGuest(
                                          index,
                                          i,
                                          "children",
                                          "plus"
                                        )
                                      }
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>
                                <div className="guestCounter">
                                  <p
                                    style={{
                                      marginLeft: "5px",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    Infants
                                  </p>
                                  <div className="quantityies qucuentlft">
                                    <span
                                      className="qunatity-adjust"
                                      onClick={() =>
                                        updateGuest(
                                          index,
                                          i,
                                          "infants",
                                          "minus"
                                        )
                                      }
                                    >
                                      −
                                    </span>
                                    {r.infants}
                                    <span
                                      className="qunatity-adjust"
                                      onClick={() =>
                                        updateGuest(index, i, "infants", "plus")
                                      }
                                    >
                                      +
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    : ""}
                </div>
              );
            })}
            {/* {selectedRoomtype[0]?.room_type?.tax_group?.tax_group_name && (
              <Row className="stayBox">
                <Form.Item
                  name={`tax-group`}
                  label={
                    selectedRoomtype[0]?.room_type?.tax_group?.tax_group_name
                  }
                >
                  <Radio.Group
                    className="tick-radio"
                    onChange={(e) => handleTaxEdit(e.target.value)}
                  >
                    <Radio.Button
                      value="remove"
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {selectedRoomtype[0]?.room_type?.tax_group
                        ?.taxes_inclusive_in_product_price === "remove" ? (
                        <svg
                          width="13px"
                          style={{ marginRight: "2px" }}
                          viewBox="0 0 123 102"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                            fill="#BE3D5D"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                      Remove
                    </Radio.Button>
                    <Radio.Button
                      value={true}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {selectedRoomtype[0]?.room_type?.tax_group
                        ?.taxes_inclusive_in_product_price === true ? (
                        <svg
                          width="13px"
                          style={{ marginRight: "2px" }}
                          viewBox="0 0 123 102"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                            fill="#BE3D5D"
                          />
                        </svg>
                      ) : (
                        ""
                      )}{" "}
                      Inclusive
                    </Radio.Button>

                    <Radio.Button
                      value={false}
                      style={{
                        marginRight: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      {selectedRoomtype[0]?.room_type?.tax_group
                        ?.taxes_inclusive_in_product_price === false ? (
                        <svg
                          width="13px"
                          style={{ marginRight: "2px" }}
                          viewBox="0 0 123 102"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M4.42991 63.6294C1.56091 60.8744 0.0779147 57.2094 0.00291465 53.5194C-0.0710853 49.8304 1.26391 46.1074 4.01791 43.2384C6.76991 40.3714 10.4349 38.8874 14.1239 38.8134C17.8149 38.7374 21.5359 40.0684 24.4069 42.8254L49.1939 66.6764L98.5429 3.98842L100.311 5.33742L98.5409 3.98242C98.6819 3.79942 98.8419 3.64342 99.0199 3.51642C101.956 0.973418 105.641 -0.174582 109.243 0.0214178V0.0174178L109.419 0.0334178C113.042 0.273418 116.581 1.88342 119.194 4.79942C121.852 7.76442 123.057 11.5304 122.856 15.2114H122.86L122.844 15.3874C122.608 18.9454 121.053 22.4224 118.235 25.0194L59.0109 97.1094L59.0149 97.1134C58.9039 97.2544 58.7789 97.3754 58.6429 97.4814C55.8699 99.9164 52.3679 101.11 48.8859 101.05C45.3749 100.989 41.8709 99.6544 39.1449 97.0344L4.42991 63.6294Z"
                            fill="#BE3D5D"
                          />
                        </svg>
                      ) : (
                        ""
                      )}
                      Exclusive
                    </Radio.Button>
                  </Radio.Group>
                </Form.Item>
              </Row>
            )} */}

            <Row className="stayBox">
              <Col
                md={12}
                sm={12}
                lg={12}
                xxl={12}
                xs={24}
                className="gutter-row"
              >
                <p>
                  Tax : {rsSymbol}
                  {totalCalculatedTax}
                </p>
              </Col>
              <Col
                md={12}
                sm={12}
                lg={12}
                xxl={12}
                xs={24}
                className="gutter-row"
              >
                <p>
                  Total : {rsSymbol}
                  {totalCalculatedPrice}
                </p>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </Modal>
  );
};

export default React.memo(AddAddtionalRoom);
