import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Table,
  Input,
  Modal,
  Space,
  Button,
  Form,
  Spin,
  message,
  Tooltip,
  Select,
  DatePicker,
} from "antd";
import {
  SearchOutlined,
  ImportOutlined,
  ExclamationCircleOutlined,
  CloseOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import { UserTableStyleWrapper } from "../../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../../Customer/Style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { LoadingOutlined } from "@ant-design/icons";
import { Popover } from "../../../components/popup/popup";
import {
  getCustomerList,
  filterListData,
  ExportCustomer,
} from "../../../redux/customer/actionCreator";

import commonFunction from "../../../utility/commonFunctions";
import { useHistory } from "react-router-dom";
import "../../Customer/customer.css";
import { getItem, setItem } from "../../../utility/localStorageControl";
import {
  getMasterList,
  exportMasters,
} from "../../../redux/HotelReports/actionCreator";
import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import moment from "moment";
import { handleMasterReservationDate } from "../../../redux/receipts/actionCreator";
import { getSingleReservation } from "../../../redux/reservation/actionCreator";

const Master = () => {
  const { path } = useRouteMatch();
  let location = useLocation();
  const [startdate, setstartdate] = useState();
  const [DateRanged, setDateRange] = useState("Today");

  const [endDate1, setenddate] = useState();
  const dispatch = useDispatch();
  const [sizeOfData, setSize] = useState(10);
  let searchInput = useRef(null);
  const history = useHistory();
  const [form] = Form.useForm();
  const [changePage, setChangePage] = useState(1);
  const [totalCustomer, setTotalCustomer] = useState();
  const [CustomerListData, setCustomerListData] = useState([]);
  const [masterList, setMasterList] = useState([]);
  const [exportType, setExportType] = useState();
  const [loading, setLoading] = useState(false);
  let isMounted = useRef(true);
  const [modalVisible, setModelVisible] = useState(false);
  const [loader, setLoader] = useState(true);
  let [filterObj, setFilterObj] = useState({});
  const { Search } = Input;
  const [reservationLoading, setReservationLoading] = useState({
    id: "",
    loading: false,
  });
  const { currentRegisterData, startDate, endDate } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
      startDate: state.receipts.masterReservationStartDate,
      endDate: state.receipts.masterReservationEndDate,
    };
  });
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  useEffect(() => {
    setWindowWidth(
      window.innerWidth > 0 ? window.innerWidth : window.outerWidth
    );
    setItem("windowWidth", windowWidth);
  }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
  const deviceWidth = getItem("windowWidth");

  let searchText = "";

  async function fetchMasterList() {
    setLoader(true);
    const response = await dispatch(
      getMasterList(currentRegisterData?._id, changePage, filterObj)
    );
    setLoader(false);
    if (response && !response.error) {
      setMasterList(response.data);
    } else if (response) {
      message.error(response.message);
    }
  }
  useEffect(() => {
    return () => {
      dispatch(handleMasterReservationDate(null, null));
    };
  }, []);
  useEffect(() => {
    fetchMasterList();
  }, [changePage, filterObj]);

  let changePageData = async (value) => {
    setChangePage(value);
  };

  let email = localStorage.getItem("email_id");

  const onSubmit = async (values) => {
    setLoading(true);
    if (loading) {
      setModelVisible(false);
      setLoading(false);
    }
    values.type = exportType;
    values.register_id = currentRegisterData?._id;
    if (DateRanged === "custom") {
      values.endDate = endDate1;
      values.startDate = startdate;
    }
    console.log("values", values);
    let ExportCustomerAPI = await dispatch(exportMasters(values));
    if (!ExportCustomerAPI.error) {
      setLoading(false);
      setModelVisible(false);
    }
  };

  const handleCancel = (e) => {
    setModelVisible(false);
  };

  const onSearch = async (value) => {
    let searchtext = value;
    if (searchtext === "") {
      delete filterObj.reservationStringId;
      setFilterObj({ ...filterObj });
    } else {
      setFilterObj({ reservationStringId: searchtext });
    }
  };
  useEffect(() => {
    if (startDate) {
      setFilterObj({ ...filterObj, startDate: startDate, endDate: endDate });
    }
  }, [startDate]);

  const content = (
    <>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("PDF");
        }}
      >
        <FeatherIcon size={16} icon="book-open" />
        <span>PDF</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("XLSX");
        }}
      >
        <FeatherIcon size={16} icon="x" />
        <span>Excel (XLSX)</span>
      </NavLink>
      <NavLink
        to="#"
        onClick={() => {
          setModelVisible(true);
          setExportType("CSV");
        }}
      >
        <FeatherIcon size={16} icon="file" />
        <span>CSV</span>
      </NavLink>
    </>
  );
  let locale = {
    emptyText: (
      <Spin
        style={{
          marginTop: "20px",
        }}
      />
    ),
  };
  const dataSource = [];
  if (CustomerListData.length > 0)
    CustomerListData.map((value, i) => {
      const {
        _id,
        name,
        mobile,
        order_count,
        order_value,
        created_at,
        last_seen,
      } = value;
      return dataSource.push({
        id: _id,
        key: i,
        name: name,
        mobile: mobile,
        order_count: order_value,
        order_value: order_count,
        created_at: created_at,
        last_seen: last_seen,
      });
    });

  const columns = [
    {
      title: "NAME",
      dataIndex: "customerId",
      key: "customerId",
      fixed: "left",
      render(text, record) {
        return {
          children: <div>{text?.name}</div>,
        };
      },
    },
    {
      title: "REFERENCE",
      dataIndex: "reservationStringId",
      key: "reservationStringId",
      render(text, record) {
        return {
          children: (
            <div>
              {reservationLoading?.id == record._id &&
              reservationLoading.loading ? (
                <Spin
                  style={{
                    marginTop: "10px",
                    color: "#CA366E",
                  }}
                />
              ) : (
                <div style={{ color: "#008cba", display: "inline" }}>
                  #{text}
                </div>
              )}
            </div>
          ),
        };
      },
    },
    {
      title: "STATUS",
      dataIndex: "isCancelled",
      key: "isCancelled",
      align: "left",
      render(text, record) {
        return {
          children: (
            <p
              style={{
                backgroundColor: record.isReceipt
                  ? "rgb(212, 243, 255)"
                  : text
                  ? "#ff4d4f10"
                  : "rgb(240, 242, 242)",
                borderRadius: "15px",
                color: record.isReceipt
                  ? "rgb(24, 101, 132)"
                  : text
                  ? "#ff4d4f"
                  : "#393939",
                display: "inline",
                fontSize: "13px",
                fontWeight: 500,
                padding: "4.5px 11.85px",
              }}
            >
              {record.isReceipt
                ? "Completed"
                : text
                ? "Cancelled"
                : "Confirmed"}
            </p>
          ),
        };
      },
    },
    {
      title: "SOURCE",
      dataIndex: "general",
      key: "general",
      align: "left",
      render: (text) => <span>{text?.source}</span>,
    },
    {
      title: "CHANNEL",
      dataIndex: "general",
      key: "general",
      align: "left",
      render: (text) => <span>{text?.channel}</span>,
    },
    {
      title: "ROOMS",
      dataIndex: "roomInfo",
      key: "roomInfo",
      align: "left",
      render: (text) => <span>{text?.length} room(s)</span>,
    },
    {
      title: "CHECK-IN",
      dataIndex: "reservationInDateAndTime",
      key: "reservationInDateAndTime",
      align: "left",
      render: (last_seen, record) => (
        <span>{commonFunction.convertToDate(last_seen, "MMM DD, Y")}</span>
      ),
    },
    {
      title: "CHECK-OUT",
      dataIndex: "reservationOutDateAndTime",
      key: "reservationOutDateAndTime",
      align: "left",
      render: (last_seen, record) => (
        <span>
          {last_seen == "-"
            ? last_seen
            : commonFunction.convertToDate(last_seen, "MMM DD, Y")}
        </span>
      ),
    },
    {
      title: "NUMBER OF NIGHTS",
      dataIndex: "totalNights",
      key: "totalNights",
      align: "left",
      render: (text) => <span>{text} night(s)</span>,
    },
    {
      title: "ROOM CHARGES",
      dataIndex: "payment",
      key: "payment",
      align: "left",
      render: (text) => (
        <span>
          {text?.currency}
          {text?.roomCharges}
        </span>
      ),
    },
    {
      title: "TAX",
      dataIndex: "payment",
      key: "payment",
      align: "left",
      render: (text) => (
        <span>
          {text?.currency}
          {text?.roomChargesTax}
        </span>
      ),
    },
    {
      title: "EXTRAS",
      dataIndex: "payment",
      key: "payment",
      align: "left",
      render: (text) => (
        <span>
          {text?.currency}
          {text?.extraCharges}
        </span>
      ),
    },
    {
      title: "TOTAL",
      dataIndex: "payment",
      key: "payment",
      align: "left",
      render: (text) => (
        <span>
          {text?.currency}
          {text?.grandTotal}
        </span>
      ),
    },
    {
      title: "PAYMENT RECEIVED",
      dataIndex: "payment",
      key: "payment",
      align: "left",
      render: (text) => (
        <span>
          {text?.currency}
          {text?.received}
        </span>
      ),
    },
    {
      title: "OUTSTANDING",
      dataIndex: "payment",
      key: "payment",
      align: "left",
      render: (text) => (
        <span>
          {text?.currency}
          {text?.grandTotal - text?.received}
        </span>
      ),
    },
    {
      title: "BOOKED ON",
      dataIndex: "reservationBookTime",
      key: "reservationBookTime",
      align: "left",
      render: (text) => (
        <span>{commonFunction.convertToDate(text, "MMM DD, Y, h:mm A")}</span>
      ),
    },
    {
      title: "BOOKED BY",
      dataIndex: "reservationCreatedStaffUserId",
      key: "reservationCreatedStaffUserId",
      align: "left",
      render: (text) => <span>{text?.username}</span>,
    },
  ];

  const handleReservation = async (id) => {
    history.push("/reservation", { reservationData: id });
  };
  return (
    <>
      <Main>
        <CardToolbox>
          <PageHeader
            ghost
            className="comman-other-custom-pageheader receipts-top0"
            subTitle={
              <>
                <div className="table_titles">
                  <h2>Masters</h2>
                </div>
                <div
                  style={{ boxShadow: "none", marginLeft: "10px" }}
                  className="search_lrm"
                >
                  <Search
                    className="receipts-search"
                    placeholder="Search by Reference Number"
                    enterButton
                    onSearch={(e) => onSearch(e)}
                    suffix={<SearchOutlined style={{ fontSize: "20px" }} />}
                    allowClear={{
                      clearIcon: <CloseOutlined />,
                    }}
                  />
                </div>
              </>
            }
            buttons={[
              <div key="1" className="page-header-actions custom_action">
                <Tooltip
                  title={
                    <p
                      style={{
                        textAlign: "center",
                        marginTop: "10px",
                        marginLeft: "10px",
                      }}
                    >
                      Showing master from{" "}
                      {startDate
                        ? moment(startDate).format("MMMM Do YYYY")
                        : moment()
                            .startOf("month")
                            .format("MMMM Do YYYY")}
                      {" to"}{" "}
                      {endDate
                        ? moment(endDate).format("MMMM Do YYYY")
                        : moment()
                            .endOf("month")
                            .format("MMMM Do YYYY")}
                    </p>
                  }
                >
                  <ExclamationCircleOutlined
                    style={{
                      cursor: "pointer",
                      marginRight: "10px",
                      fontSize: "18px",
                    }}
                  />
                </Tooltip>
                <CalendarButtonPageHeader key="5" type="master-reservattion" />
                <div className="actionBtn">
                  <Popover
                    placement="bottomLeft"
                    content={content}
                    trigger="click"
                  >
                    <Button size="middle" type="white">
                      <FeatherIcon icon="download" size={14} />
                      Export
                    </Button>
                  </Popover>
                </div>
              </div>,
            ]}
          />
        </CardToolbox>
        <Row gutter={15}>
          <Col md={24}>
            <Cards headless>
              <UserTableStyleWrapper>
                <div className="contact-table">
                  <TableWrapper className="table-responsive">
                    {loader ? (
                      <Table
                        locale={locale}
                        rowKey="_id"
                        size="small"
                        dataSource={[]}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 1300 }}
                        pagination={false}
                      />
                    ) : (
                      <Table
                        rowKey="_id"
                        size="small"
                        dataSource={masterList}
                        columns={columns}
                        fixed={true}
                        scroll={{ x: 2500 }}
                        pagination={false}
                        onRow={(row) => ({
                          onClick: () => {
                            handleReservation(row);
                          },
                        })}
                      />
                    )}
                  </TableWrapper>
                </div>
              </UserTableStyleWrapper>
              <p
                style={{
                  display: "flex",
                  justifyContent: "center",
                  cursor: "pointer",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Button
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50px",
                  }}
                  onClick={() => {
                    setChangePage(changePage - 1);
                    changePageData(changePage - 1);
                  }}
                  disabled={changePage == 1 ? true : false}
                >
                  <FeatherIcon
                    size={20}
                    icon="chevron-left"
                    style={{ position: "relative", left: "-11px", top: "2px" }}
                  />
                </Button>
                <Button
                  style={{
                    width: "32px",
                    height: "32px",
                    borderRadius: "50px",
                    marginLeft: "40px",
                  }}
                  disabled={masterList?.length < 10 ? true : false}
                  onClick={() => {
                    setChangePage(changePage + 1);
                    changePageData(changePage + 1);
                  }}
                >
                  <FeatherIcon
                    size={20}
                    icon="chevron-right"
                    style={{ position: "relative", left: "-9px", top: "2px" }}
                  />
                </Button>
              </p>
            </Cards>
          </Col>
        </Row>
        <Modal
          title="Export Master"
          visible={modalVisible}
          onOk={form.submit}
          okText={loading ? "Loading..." : "Save"}
          onCancel={handleCancel}
          width={600}
        >
          <Form form={form} name="export_customer" onFinish={onSubmit}>
            <div className="add-product-block">
              <div className="add-product-content">
                <Form.Item
                  initialValue="reservationBookTime"
                  label="Filter by "
                  name="filter_by"
                >
                  <Select
                    name="report"
                    style={{ width: "100%", marginBottom: "10px" }}
                    onChange={(value) => setDateRange(value)}
                  >
                    <Option value="reservationBookTime">Created Date</Option>
                    <Option value="reservationInDateAndTime">
                      Reservation Date
                    </Option>
                  </Select>
                </Form.Item>
                <Form.Item
                  initialValue="today"
                  label="Date Range"
                  name="dateRange"
                >
                  <Select
                    name="report"
                    style={{ width: "100%", marginBottom: "10px" }}
                    onChange={(value) => setDateRange(value)}
                  >
                    <Option value="today">Today </Option>
                    <Option value="yesterday">Yesterday</Option>
                    <Option value="this_month">This Month</Option>
                    <Option value="last_month">Last Month</Option>
                    <Option value="custom">Custom selection</Option>
                  </Select>
                </Form.Item>
                {DateRanged === "custom" ? (
                  <div>
                    <Form.Item
                      label="Start Date"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                    >
                      <DatePicker
                        style={{ height: "35px" }}
                        placeholder="dd-mm-yyyy"
                        format="YYYY-MM-DD"
                        onChange={(date, datestring) =>
                          setstartdate(datestring)
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      label="End Date"
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 12px)",
                      }}
                    >
                      <DatePicker
                        onChange={(date, datestring) => setenddate(datestring)}
                        style={{ height: "35px" }}
                        placeholder="dd-mm-yyyy"
                        format="YYYY-MM-DD"
                      />
                    </Form.Item>
                  </div>
                ) : (
                  ""
                )}
                <Form.Item
                  name="email"
                  label="Send to Email Address"
                  initialValue={email}
                  rules={[
                    {
                      required: true,
                      message: "Please enter your email",
                    },
                    { type: "email", message: "A valid email is required" },
                  ]}
                >
                  <Input placeholder="Report will be send to this email" />
                </Form.Item>
              </div>
            </div>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default Master;
