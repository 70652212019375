import React, { useState, useEffect, useRef } from "react";
import _ from "lodash";
import {
  Row,
  Col,
  Form,
  Input,
  Select,
  Radio,
  Tooltip,
  Tabs,
  TreeSelect,
  Button,
  Upload,
  Space,
  Modal,
  Descriptions,
  Divider,
  Image,
  message,
  Checkbox,
} from "antd";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { NavLink, useParams, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector, shallowEqual } from "react-redux";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main, BasicFormWrapper } from "../styled";
import { AddProductForm } from "../Product/Style";
import { getAllRegisterList } from "../../redux/register/actionCreator";
import {
  InfoCircleFilled,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { getTaxGroupList } from "../../redux/taxGroup/actionCreator";
import {
  addOrUpdateroomType,
  getOtaChannel,
} from "../../redux/roomType/actionCreator";
import "../Product/product.css";
const { TabPane } = Tabs;
import {
  LoadingOutlined,
  PlusOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { Spin } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./style.css";
import { getItem } from "../../utility/localStorageControl";

const AddEditRoomType = () => {
  const { currentRegisterData } = useSelector((state) => {
    return {
      currentRegisterData:
        state.register.RegisterList?.length > 0 &&
        state.register.RegisterList?.find((val) => val.activeHotel),
    };
  });
  const [form] = Form.useForm();
  const [addForm] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let isMounted = useRef(true);
  let [extraRateList, setExtraRateList] = useState([]);
  let [taxGroupList, setTaxGroupList] = useState([]);
  let [activeTab, changeTab] = useState("1");
  const [adultNo, setAdultNo] = useState(0);
  const [loading, setLoading] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [checkEdit, setEdit] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [roomTypeDetils, setRoomTypeDetails] = useState();
  const [RegisterNameList, setRegisterNameList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [channelRoomType, setChannelRoomType] = useState([]);
  const [channelRatePlans, setChannelRatePlans] = useState([]);
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
  };
  useEffect(() => {
    if (isMounted.current) {
      fetchTaxGroupList();
      fetchRegisterName();
    }
    return () => {
      isMounted.current = false;
    };
  }, []);
  const handleChange1 = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  const [singleRatePlan, setSingleRateplan] = useState();
  async function fetchRegisterName() {
    const getRgisterNameList = await dispatch(getAllRegisterList("sell"));
    if (isMounted.current) {
      setRegisterNameList(
        getRgisterNameList.RegisterList.filter((val) => val.type == "hotel")
      );
    }
  }

  async function fetchTaxGroupList() {
    const taxGroupList = await dispatch(getTaxGroupList("sell"));
    if (isMounted.current) {
      setTaxGroupList(taxGroupList.taxGroupList);
    }
  }

  const fetchEditData = (channelRoomTypeList) => {
    setRoomTypeDetails(location.state.roomTypeDetils);
    setAdultNo(location.state.roomTypeDetils.default_adult);
    let mainRatePlans = location.state.roomTypeDetils.rate_plan;
    setSingleRateplan(mainRatePlans[0]);
    let obj = {
      ...location.state.roomTypeDetils,
      tax_group: location?.state?.roomTypeDetils?.tax_group?._id,
      rate_name: mainRatePlans[0].rate_name,
      default_adult_rate: mainRatePlans[0].default_adult_rate,
      extra_adult_rate: mainRatePlans[0].extra_adult_rate,
      extra_infant_rate: mainRatePlans[0].extra_infant_rate,
      extra_child_rate: mainRatePlans[0].extra_child_rate,
      channel_rate_code: mainRatePlans[0]?.channel_rate_code,
      show_booking_engine: mainRatePlans[0]?.show_booking_engine,
    };

    mainRatePlans[0]?.adult_rates?.map((ratevalue, index) => {
      if (index) {
        obj[`adult_${index}_rate`] = ratevalue?.rate;
        if (ratevalue[`channel_rate_code`]) {
          obj[`channel_rate_code${index}`] = ratevalue?.channel_rate_code;
        }
      }
    });

    form.setFieldsValue(obj);
    if (location?.state?.roomTypeDetils?.room_images) {
      setFileList(location.state.roomTypeDetils.room_images);
    }
    if (
      currentRegisterData?.connect_channel &&
      currentRegisterData?.channel_key != "" &&
      location.state.roomTypeDetils?.channel_room_code != "" &&
      channelRoomTypeList?.length > 0
    ) {
      let findRateList = channelRoomTypeList.find(
        (k) => k.RoomCode == location.state.roomTypeDetils?.channel_room_code
      );

      if (findRateList) {
        setChannelRatePlans(findRateList?.RatePlans);
      }
    }
    setExtraRateList(mainRatePlans.filter((val, index) => index > 0));
  };
  const fetchChannelRoomType = async () => {
    setLoader(true);
    let response = await dispatch(
      getOtaChannel(currentRegisterData?.channel_key)
    );

    if (response && response.status == 200) {
      setChannelRoomType(response.data);
      setLoader(false);
      fetchEditData(response.data);
    } else {
      message.error("Failed to fetch ota room");
      setLoader(false);
      fetchEditData();
    }
  };
  useEffect(() => {
    if (location && location.state && location.state.roomTypeDetils) {
      if (
        currentRegisterData?.connect_channel &&
        currentRegisterData?.channel_key != ""
      ) {
        fetchChannelRoomType();
      } else {
        fetchEditData();
      }
    }
  }, []);

  function insertObject(arr, obj) {
    arr = [...arr, obj];
    return arr;
  }

  const handleSubmitBasicDetails = async (formData) => {
    setLoading(true);
    let {
      default_adult,
      default_adult_rate,
      default_children,
      default_infant,
      extra_adult_rate,
      extra_child_rate,
      extra_infant_rate,
      show_booking_engine,
      max_adult,
      max_children,
      max_infant,
      name,
      no_beds,
      rate_name,
      summary_of_roomtype,
      tax_group,
      adult_rate_plan,
      channel_room_code,
      channel_rate_code,
      room_ammenties,
      hsn_code,
    } = formData;

    let adult_rates = [];
    let channelRatePlan;
    if (channel_rate_code && channelRatePlans?.length) {
      channelRatePlan = channelRatePlans.find(
        (item) => item.RatePlanCode === channel_rate_code
      );
    }
    let ad = {
      numberOfAdult: default_adult,
      rate: default_adult_rate,
    };
    if (channelRatePlan) {
      ad["channel_rate_code"] = channel_rate_code;
      ad["channel_rate_obj"] = channelRatePlan;
    }
    adult_rates.push(ad);
    Array.from({ length: default_adult - 1 }, (_, index) => {
      let obj = {
        numberOfAdult: index + 1,
        rate: formData[`adult_${index + 1}_rate`],
      };

      if (
        channelRatePlans?.length &&
        formData[`channel_rate_code${index + 1}`]
      ) {
        obj.channel_rate_code = formData[`channel_rate_code${index + 1}`];
        let channelRatePlan = channelRatePlans.find(
          (item) =>
            item.RatePlanCode === formData[`channel_rate_code${index + 1}`]
        );
        if (channelRatePlan) {
          obj.channel_rate_obj = channelRatePlan;
        }
      }
      adult_rates.push(obj);
    });

    const rate_plan_Obj = {};

    rate_plan_Obj.channel_rate_code = channel_rate_code
      ? channel_rate_code
      : singleRatePlan?.channel_rate_code;
    if (channelRatePlan) {
      rate_plan_Obj.channel_rate_obj = channelRatePlan
        ? channelRatePlan
        : singleRatePlan?.channel_rate_obj;
    }
    rate_plan_Obj.default_adult_rate = default_adult_rate
      ? default_adult_rate
      : singleRatePlan?.default_adult_rate;
    rate_plan_Obj.extra_adult_rate =
      extra_adult_rate != undefined
        ? extra_adult_rate == ""
          ? "0"
          : extra_adult_rate
        : singleRatePlan?.extra_adult_rate
        ? singleRatePlan.extra_adult_rate
        : "0";
    rate_plan_Obj.extra_child_rate =
      extra_child_rate != undefined
        ? extra_child_rate == ""
          ? "0"
          : extra_child_rate
        : singleRatePlan?.extra_child_rate
        ? singleRatePlan.extra_child_rate
        : "0";
    rate_plan_Obj.extra_infant_rate =
      extra_infant_rate != undefined
        ? extra_infant_rate == ""
          ? "0"
          : extra_infant_rate
        : singleRatePlan?.extra_infant_rate
        ? singleRatePlan.extra_infant_rate
        : "0";
    rate_plan_Obj.show_booking_engine = show_booking_engine;

    rate_plan_Obj.rate_name = rate_name ? rate_name : singleRatePlan?.rate_name;
    rate_plan_Obj.rate_plan = adult_rate_plan;
    if (roomTypeDetils?._id) {
      rate_plan_Obj.rate_id = roomTypeDetils?.rate_plan[0]?.rate_id
        ? roomTypeDetils?.rate_plan[0]?.rate_id
        : undefined;
    }
    rate_plan_Obj["adult_rates"] = adult_rates;
    const payload = {
      name: name,
      rate_plan: [rate_plan_Obj, ...extraRateList].map((val) => {
        return {
          ...val,
          rate_id: val.rate_id
            ? val.rate_id
            : Date.now().toString(36) +
              Math.random()
                .toString(36)
                .substr(2),
        };
      }),
      no_beds: no_beds,
      default_adult: default_adult,
      default_children: default_children,
      default_infant: default_infant,
      summary_of_roomtype: summary_of_roomtype ? summary_of_roomtype : "",
      room_ammenties: room_ammenties,
      tax_group: tax_group,
      max_adult: max_adult,
      max_children: max_children,
      max_infant: max_infant,
      hsn_code: hsn_code ? hsn_code : "",
      register_id: currentRegisterData?._id,
    };
    console.log("adult_rates", adult_rates);
    if (channel_room_code) {
      let findRoomType = channelRoomType.find(
        (val) => channel_room_code == val.RoomCode
      );
      if (findRoomType) {
        payload["channel_room_code"] = channel_room_code;
        payload["channel_room_name"] = findRoomType.RoomName;
      }
    }
    let addRoomType;
    if (roomTypeDetils && roomTypeDetils._id) {
      let formData = new FormData();
      const images = fileList ?? [];
      if (fileList && fileList.length > 0) {
        for (const [index, oneImage] of (fileList ?? []).entries()) {
          if (oneImage.originFileObj) {
            formData.append(`files-${index}`, oneImage.originFileObj);
            // images.splice(index, 1);
          }
        }
      }
      payload["room_images"] = images.filter(
        (value) => value.response == undefined
      );
      Object.keys(payload).forEach((key) => {
        formData.append(key, JSON.stringify(payload[key]));
      });
      addRoomType = await dispatch(
        addOrUpdateroomType(formData, roomTypeDetils._id)
      );
    } else {
      addRoomType = await dispatch(addOrUpdateroomType(payload));
    }
    if (addRoomType && !addRoomType.error) {
      form.resetFields();
      history.push("/room-type");
      setLoading(false);
    } else if (addRoomType) {
      message.error({
        content: addRoomType.message,
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
      setLoading(false);
    } else {
      message.error({
        content: "Somthing is rong",
        style: {
          float: "center",
          marginTop: "2vh",
        },
      });
    }
  };
  const getOccupancyLabel = (num) => {
    switch (num) {
      case 1:
        return "Single occupancy rate";
      case 2:
        return "Double occupancy rate";
      case 3:
        return "Triple occupancy rate";
      case 4:
        return "Quadruple occupancy rate";
      default:
        return `${num} Adult(s) rate`;
    }
  };
  const getOtaLabel = (num) => {
    switch (num) {
      case 1:
        return "Single ota room rate";
      case 2:
        return "Double ota room rate";
      case 3:
        return "Triple ota room rate";
      case 4:
        return "Quadruple ota room rate";
      default:
        return `${num} Adult(s) ota room rate`;
    }
  };
  const showAddRateModal = (val) => {
    setOpenAddModal(true);

    val.adult_rates?.map((ratevalue, index) => {
      if (index) {
        val[`adult_${index}_rate`] = ratevalue?.rate;
        if (ratevalue[`channel_rate_code`]) {
          val[`channel_rate_code${index}`] = ratevalue?.channel_rate_code;
        }
      }
    });
    if (val && val.rate_name) {
      if (val?.channel_rate_code && val.channel_rate_code != "") {
        addForm.setFieldsValue(val);
      } else {
        addForm.setFieldsValue({ ...val, channel_rate_code: undefined });
      }
    } else {
      addForm.resetFields();
    }
  };

  const submitAddRate = (val) => {
    setOpenAddModal(false);
    const newObj = {};
    newObj.default_adult_rate = val.default_adult_rate;
    newObj.extra_adult_rate = val.extra_adult_rate ? val.extra_adult_rate : "0";
    newObj.extra_child_rate = val.extra_child_rate ? val.extra_child_rate : "0";
    newObj.extra_infant_rate = val.extra_infant_rate
      ? val.extra_infant_rate
      : "0";
    newObj.show_booking_engine = val.show_booking_engine;
    newObj.rate_name = val.rate_name;
    newObj.rate_plan = val.adult_rate_plan ? val.adult_rate_plan : [];
    let adult_rates = [];
    let ad = {
      numberOfAdult: adultNo,
      rate: val.default_adult_rate,
    };
    let channelRatePlan;
    if (val?.channel_rate_code && channelRatePlans?.length) {
      channelRatePlan = channelRatePlans.find(
        (item) => item.RatePlanCode == val?.channel_rate_code
      );
    }
    if (channelRatePlan) {
      newObj.channel_rate_code = val?.channel_rate_code;
      newObj.channel_rate_obj = channelRatePlan;
      ad.channel_rate_code = val?.channel_rate_code;
      ad.channel_rate_obj = channelRatePlan;
    }
    adult_rates.push(ad);
    Array.from({ length: adultNo - 1 }, (_, index) => {
      let obj = {
        numberOfAdult: index + 1,
        rate: val[`adult_${index + 1}_rate`],
      };
      if (channelRatePlans?.length && val[`channel_rate_code${index + 1}`]) {
        obj.channel_rate_code = val[`channel_rate_code${index + 1}`];
        let channelRatePlan = channelRatePlans.find(
          (item) => item.RatePlanCode === val[`channel_rate_code${index + 1}`]
        );
        if (channelRatePlan) {
          obj.channel_rate_obj = channelRatePlan;
        }
      }
      adult_rates.push(obj);
    });
    newObj["adult_rates"] = adult_rates;

    if (checkEdit) {
      extraRateList[editIndex] = newObj;
      setExtraRateList([...extraRateList]);
      setEdit(false);
      setEditIndex();
      addForm.resetFields();
    } else {
      setExtraRateList(insertObject(extraRateList, newObj));
      addForm.resetFields();
    }
  };

  const confirmDelete = (index, rateName) => {
    Modal.confirm({
      title: "Are you sure you wants to Delete this Rate Plan?",
      okText: "Yes",
      okType: "success",
      onOk: () => {
        extraRateList.splice(index, 1);
        setExtraRateList([...extraRateList]);
      },
    });
  };
  const validateFields = (tab) => {
    if (tab == 2) {
      form
        .validateFields([
          "name",
          "tax_group",
          "default_adult",
          "max_adult",
          "default_children",
          "max_children",
          "default_infant",
          "max_infant",
        ])
        .then(() => {
          changeTab(tab);
        })
        .catch((errorInfo) => {});
    } else {
      form
        .validateFields()
        .then(() => {
          changeTab(tab);
        })
        .catch((errorInfo) => {
          const filteredErrors = errorInfo?.errorFields?.filter(
            (item) => !item.errors.includes("number of bed is required")
          );
          if (filteredErrors?.length == 0) {
            changeTab(tab);
          }
        });
    }
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const amenities = [
    "Wi-Fi/Internet Access",
    "Television (with satellite/cable channels)",
    "Air Conditioning/Heating",
    "Comfortable Bedding (high-quality linens, pillows, mattress)",
    "Blackout Curtains",
    "Room Service",
    "Mini Bar (stocked with beverages and snacks)",
    "Coffee/Tea Maker (electric kettle, coffee machine, supplies)",
    "Complimentary Bottled Water",
    "Snack Basket",
    "Toiletries (soap, shampoo, conditioner, body lotion)",
    "Towels (bath, hand, face)",
    "Hairdryer",
    "Bathrobe",
    "Slippers",
    "Shower Cap",
    "Shaving Kit",
    "Dental Kit",
    "Makeup/Shaving Mirror",
    "Work Desk and Chair",
    "Closet/Wardrobe (with hangers)",
    "Iron and Ironing Board",
    "Luggage Rack",
    "Sofa/Seating Area",
    "In-room Safe",
    "Door Lock (electronic or manual)",
    "Smoke Detector",
    "Fire Extinguisher",
    "Telephone (for internal/external calls)",
    "Alarm Clock/Clock Radio",
    "Power Outlets (with USB ports)",
    "Bluetooth Speaker",
    "Room Directory (with hotel information)",
    "Laundry Bag",
    "Shoe Shine Kit",
    "Sewing Kit",
    "Notepad and Pen",
    "Turndown Service (optional)",
    "Wheelchair-Accessible Facilities",
    "Extra Bedding (blankets, pillows)",
    "Baby Cot (on request)",
    "Connecting Rooms (for families)",
    "Welcome Kit (chocolates, fruit basket)",
  ];

  return (
    <>
      <Main className="padding-top-form">
        <Row gutter={15}>
          <Col xs={24}>
            <Cards headless>
              <Row gutter={25} justify="center">
                <Col xxl={12} md={16} sm={18} xs={24}>
                  <AddProductForm>
                    <Form
                      style={{ width: "100%" }}
                      form={form}
                      name="addRate"
                      onFinish={handleSubmitBasicDetails}
                      className="comman-input"
                    >
                      <BasicFormWrapper>
                        <Tabs
                          activeKey={activeTab}
                          onChange={(val) => {
                            if (val == 1) {
                              changeTab("1");
                            } else {
                              validateFields(val.toString());
                            }
                          }}
                        >
                          <TabPane tab="Details" key="1">
                            {loader ? (
                              <Spin
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              />
                            ) : (
                              <div className="add-product-block">
                                <Row gutter={15}>
                                  <Col xs={24}>
                                    <div className="add-product-content">
                                      <Form.Item
                                        name="name"
                                        label="Room Type"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "Room Type name is required",
                                          },
                                        ]}
                                      >
                                        <Input
                                          style={{ marginBottom: 10 }}
                                          placeholder="Room Type name"
                                        />
                                      </Form.Item>

                                      <Form.Item
                                        name="tax_group"
                                        label="Tax Group"
                                        rules={[
                                          {
                                            required: true,
                                            message: "Please select tax group",
                                          },
                                        ]}
                                      >
                                        <Select
                                          placeholder="Select Tax Group"
                                          showSearch
                                          style={{ marginBottom: 10 }}
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          dropdownRender={(menu) => (
                                            <div>
                                              {menu}
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexWrap: "nowrap",
                                                  padding: 8,
                                                }}
                                              >
                                                <NavLink
                                                  to="/settings/taxgroup/add/taxes_group"
                                                  style={{
                                                    flex: "none",
                                                    padding: "8px",
                                                    display: "block",
                                                    cursor: "pointer",
                                                  }}
                                                >
                                                  <PlusOutlined
                                                    style={{
                                                      color: "rgb(0, 140, 186)",
                                                    }}
                                                  />
                                                  <label
                                                    style={{
                                                      color: "rgb(0, 140, 186)",
                                                    }}
                                                  >
                                                    Add New Tax Group
                                                  </label>
                                                </NavLink>
                                              </div>
                                            </div>
                                          )}
                                        >
                                          {taxGroupList.map((taxGrp) => (
                                            <option
                                              key={taxGrp._id}
                                              value={taxGrp._id}
                                            >
                                              {taxGrp.tax_group_name}
                                            </option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item
                                        name="hsn_code"
                                        label="Hsn/Sac code"
                                      >
                                        <Input
                                          style={{ marginBottom: 10 }}
                                          placeholder="Hsn/Sac code"
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name="default_adult"
                                        style={{
                                          display: "inline-block",
                                          width: "calc(50% - 12px)",
                                        }}
                                        label="Default Adult Stay"
                                        rules={[
                                          {
                                            pattern: new RegExp(
                                              /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                                            ),
                                            message:
                                              "Adult numbers cannot be negative",
                                          },
                                          {
                                            required: true,
                                            message:
                                              "Please Enter default adult numbers",
                                          },
                                        ]}
                                      >
                                        <Input
                                          min={0}
                                          type="number"
                                          step="any"
                                          placeholder="Enter default adult numbers"
                                          style={{ marginBottom: 10 }}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9,.]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                          onBlur={(e) => {
                                            e.target.value !== ""
                                              ? setAdultNo(+e.target.value)
                                              : setAdultNo(0);
                                          }}
                                        />
                                      </Form.Item>
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "24px",
                                          lineHeight: "32px",
                                          textAlign: "center",
                                        }}
                                      ></span>
                                      <Form.Item
                                        label="Max Adults"
                                        style={{
                                          display: "inline-block",
                                          width: "calc(50% - 12px)",
                                        }}
                                        name="max_adult"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "maximum adult number is required",
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          initialValue={0}
                                          style={{ marginBottom: 10 }}
                                          placeholder="Max Adults"
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name="default_children"
                                        style={{
                                          display: "inline-block",
                                          width: "calc(50% - 12px)",
                                        }}
                                        label="Default Children Stay"
                                        rules={[
                                          {
                                            pattern: new RegExp(
                                              /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                                            ),
                                            message:
                                              "Children cannot be negative",
                                          },
                                          {
                                            required: true,
                                            message:
                                              "Please Enter default children numbers",
                                          },
                                        ]}
                                      >
                                        <Input
                                          min={0}
                                          type="number"
                                          step="any"
                                          placeholder="Enter default children numbers"
                                          style={{ marginBottom: 10 }}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9,.]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "24px",
                                          lineHeight: "32px",
                                          textAlign: "center",
                                        }}
                                      ></span>
                                      <Form.Item
                                        label="Max Children"
                                        name="max_children"
                                        style={{
                                          display: "inline-block",
                                          width: "calc(50% - 12px)",
                                        }}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "maximum child number is required",
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          initialValue={0}
                                          style={{ marginBottom: 10 }}
                                          placeholder="Max Children"
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name="default_infant"
                                        style={{
                                          display: "inline-block",
                                          width: "calc(50% - 12px)",
                                        }}
                                        label="Default Infant Stay"
                                        rules={[
                                          {
                                            pattern: new RegExp(
                                              /^[+]?([0-9]+(?:[\.][0-9]*)?|\.[0-9]+)$/
                                            ),
                                            message:
                                              "Infant cannot be negative",
                                          },
                                          {
                                            required: true,
                                            message:
                                              "Please Enter default infant numbers",
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          placeholder="Enter default infants numbers"
                                          initialValue={0}
                                          style={{ marginBottom: 10 }}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      <span
                                        style={{
                                          display: "inline-block",
                                          width: "24px",
                                          lineHeight: "32px",
                                          textAlign: "center",
                                        }}
                                      ></span>
                                      <Form.Item
                                        label="Max Infants"
                                        name="max_infant"
                                        style={{
                                          display: "inline-block",
                                          width: "calc(50% - 12px)",
                                        }}
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "maximum infant number is required",
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          initialValue={0}
                                          placeholder="Max Infants"
                                          style={{ marginBottom: 10 }}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      {currentRegisterData?.channel_key != "" &&
                                        currentRegisterData?.connect_channel &&
                                        roomTypeDetils?._id && (
                                          <Form.Item
                                            name="channel_room_code"
                                            label="Ota room type"
                                          >
                                            <Select
                                              placeholder="Select Ota room type"
                                              showSearch
                                              style={{ marginBottom: 10 }}
                                              filterOption={(input, option) =>
                                                option.children
                                                  .toLowerCase()
                                                  .indexOf(
                                                    input.toLowerCase()
                                                  ) >= 0
                                              }
                                              onChange={(val) => {
                                                let findRate = channelRoomType.find(
                                                  (k) => k.RoomCode == val
                                                );
                                                if (findRate) {
                                                  setChannelRatePlans(
                                                    findRate.RatePlans
                                                  );
                                                }
                                              }}
                                            >
                                              {channelRoomType.map((taxGrp) => (
                                                <option
                                                  key={taxGrp.RoomCode}
                                                  value={taxGrp.RoomCode}
                                                >
                                                  {taxGrp.RoomName}
                                                </option>
                                              ))}
                                            </Select>
                                          </Form.Item>
                                        )}
                                      <div
                                        className="add-form-action"
                                        style={{ margin: "10px 0px 2px 0px" }}
                                      >
                                        <Form.Item>
                                          <NavLink to="/room-type">
                                            <Button
                                              className="btn-cancel btn-custom"
                                              style={{ background: "white" }}
                                            >
                                              Go Back
                                            </Button>
                                          </NavLink>
                                          <Button
                                            size="small"
                                            className="btn-custom"
                                            type="primary"
                                            raised
                                            onClick={() => validateFields("2")}
                                          >
                                            Next
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </TabPane>
                          <TabPane tab="Rate" key="2">
                            {loader ? (
                              <Spin
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              />
                            ) : (
                              <div className="add-product-block">
                                <Row gutter={15}>
                                  <Col span={24}>
                                    <Form.Item
                                      label="Rate Name"
                                      name="rate_name"
                                      rules={[
                                        {
                                          required: true,
                                          message: "Rate name is required",
                                        },
                                      ]}
                                    >
                                      <Input
                                        style={{ marginBottom: 10 }}
                                        placeholder="Rate Name"
                                      />
                                    </Form.Item>
                                    {currentRegisterData?.channel_key != "" &&
                                    currentRegisterData?.connect_channel &&
                                    roomTypeDetils?._id ? (
                                      <div>
                                        <Form.Item
                                          label={`Default Adult Rate (for ${adultNo})`}
                                          name="default_adult_rate"
                                          style={{
                                            display: "inline-block",
                                            width: "calc(50% - 12px)",
                                          }}
                                          rules={[
                                            {
                                              required: true,
                                              message:
                                                "default adult rate is required",
                                            },
                                          ]}
                                        >
                                          <Input
                                            type="number"
                                            min={0}
                                            initialValue={0}
                                            placeholder="Enter default adult rate"
                                            style={{
                                              marginBottom: 20,
                                              width: "100%",
                                              marginRight: "10px",
                                            }}
                                            onKeyPress={(event) => {
                                              if (event.key.match("[0-9]+")) {
                                                return true;
                                              } else {
                                                return event.preventDefault();
                                              }
                                            }}
                                          />
                                        </Form.Item>
                                        <span
                                          style={{
                                            display: "inline-block",
                                            width: "24px",
                                            lineHeight: "32px",
                                            textAlign: "center",
                                          }}
                                        ></span>
                                        <Form.Item
                                          name="channel_rate_code"
                                          label="Default ota room rate"
                                          style={{
                                            display: "inline-block",
                                            width: "calc(50% - 12px)",
                                          }}
                                        >
                                          <Select
                                            placeholder="Select ota room rate"
                                            showSearch
                                            style={{ marginBottom: 10 }}
                                            filterOption={(input, option) =>
                                              option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                              0
                                            }
                                          >
                                            {channelRatePlans.map((taxGrp) => (
                                              <option
                                                key={taxGrp.RatePlanCode}
                                                value={taxGrp.RatePlanCode}
                                              >
                                                {taxGrp.RatePlanName}
                                              </option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    ) : (
                                      <Form.Item
                                        label={`Default Adult Rate (for ${adultNo})`}
                                        name="default_adult_rate"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "default adult rate is required",
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          initialValue={0}
                                          placeholder="Enter default adult rate"
                                          style={{
                                            marginBottom: 20,
                                            width: "100%",
                                            marginRight: "10px",
                                          }}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                    )}
                                    {currentRegisterData?.channel_key != "" &&
                                    currentRegisterData?.connect_channel &&
                                    roomTypeDetils?._id
                                      ? Array.from(
                                          { length: adultNo - 1 },
                                          (_, index) => (
                                            <div>
                                              <Form.Item
                                                key={index}
                                                label={getOccupancyLabel(
                                                  index + 1
                                                )}
                                                name={`adult_${index + 1}_rate`}
                                                rules={[
                                                  {
                                                    required: true,
                                                    message: `Rate for ${getOccupancyLabel(
                                                      index + 1
                                                    ).toLowerCase()} is required`,
                                                  },
                                                ]}
                                                style={{
                                                  display: "inline-block",
                                                  width: "calc(50% - 12px)",
                                                }}
                                              >
                                                <Input
                                                  type="number"
                                                  min={0}
                                                  placeholder={`Enter rate for ${index +
                                                    1} adult(s)`}
                                                  style={{
                                                    marginBottom: 20,
                                                    width: "100%",
                                                  }}
                                                  onKeyPress={(event) => {
                                                    if (
                                                      event.key.match("[0-9]+")
                                                    ) {
                                                      return true;
                                                    } else {
                                                      return event.preventDefault();
                                                    }
                                                  }}
                                                />
                                              </Form.Item>
                                              <span
                                                style={{
                                                  display: "inline-block",
                                                  width: "24px",
                                                  lineHeight: "32px",
                                                  textAlign: "center",
                                                }}
                                              ></span>
                                              <Form.Item
                                                name={`channel_rate_code${index +
                                                  1}`}
                                                label={getOtaLabel(index + 1)}
                                                style={{
                                                  display: "inline-block",
                                                  width: "calc(50% - 12px)",
                                                }}
                                              >
                                                <Select
                                                  placeholder="Select ota room rate"
                                                  showSearch
                                                  style={{ marginBottom: 10 }}
                                                  filterOption={(
                                                    input,
                                                    option
                                                  ) =>
                                                    option.children
                                                      .toLowerCase()
                                                      .indexOf(
                                                        input.toLowerCase()
                                                      ) >= 0
                                                  }
                                                >
                                                  {channelRatePlans.map(
                                                    (taxGrp) => (
                                                      <option
                                                        key={
                                                          taxGrp.RatePlanCode
                                                        }
                                                        value={
                                                          taxGrp.RatePlanCode
                                                        }
                                                      >
                                                        {taxGrp.RatePlanName}
                                                      </option>
                                                    )
                                                  )}
                                                </Select>
                                              </Form.Item>
                                            </div>
                                          )
                                        )
                                      : Array.from(
                                          { length: adultNo - 1 },
                                          (_, index) => (
                                            <Form.Item
                                              key={index}
                                              label={getOccupancyLabel(
                                                index + 1
                                              )} // Set dynamic label
                                              name={`adult_${index + 1}_rate`}
                                              rules={[
                                                {
                                                  required: true,
                                                  message: `Rate for ${getOccupancyLabel(
                                                    index + 1
                                                  ).toLowerCase()} is required`,
                                                },
                                              ]}
                                            >
                                              <Input
                                                type="number"
                                                min={0}
                                                placeholder={`Enter rate for ${index +
                                                  1} adult(s)`}
                                                style={{
                                                  marginBottom: 20,
                                                  width: "100%",
                                                }}
                                                onKeyPress={(event) => {
                                                  if (
                                                    event.key.match("[0-9]+")
                                                  ) {
                                                    return true;
                                                  } else {
                                                    return event.preventDefault();
                                                  }
                                                }}
                                              />
                                            </Form.Item>
                                          )
                                        )}

                                    {/* {currentRegisterData?.channel_key != "" &&
                                      currentRegisterData?.connect_channel && (
                                        <Form.Item
                                          name="channel_rate_code"
                                          label="Ota room rate"
                                        >
                                          <Select
                                            placeholder="Select Ota room rate"
                                            showSearch
                                            style={{ marginBottom: 10 }}
                                            filterOption={(input, option) =>
                                              option.children
                                                .toLowerCase()
                                                .indexOf(input.toLowerCase()) >=
                                              0
                                            }
                                          >
                                            {channelRatePlans.map((taxGrp) => (
                                              <option
                                                key={taxGrp.RatePlanCode}
                                                value={taxGrp.RatePlanCode}
                                              >
                                                {taxGrp.RatePlanName}
                                              </option>
                                            ))}
                                          </Select>
                                        </Form.Item>
                                      )} */}
                                    {/* </div> */}
                                    {/* 
                                  <Form.List name="rate_plan">
                                    {(fields, { add, remove }) => (
                                      <>
                                        {fields.map((field) => (
                                          <div className="ratePlanSpace">
                                            <Form.Item
                                              noStyle
                                              shouldUpdate={(
                                                prevValues,
                                                curValues
                                              ) =>
                                                prevValues.area !==
                                                  curValues.area ||
                                                prevValues.sights !==
                                                  curValues.sights
                                              }
                                            >
                                              {() => (
                                                <Form.Item
                                                  {...field}
                                                  label="Number of Adults"
                                                  name={[
                                                    field.name,
                                                    "no_adults",
                                                  ]}
                                                >
                                                  <Input
                                                    type="number"
                                                    min={0}
                                                    placeholder="Enter number of adults"
                                                    initialValue={0}
                                                    style={{ marginBottom: 20 }}
                                                    onKeyPress={(event) => {
                                                      if (
                                                        event.key.match(
                                                          "[0-9]+"
                                                        )
                                                      ) {
                                                        return true;
                                                      } else {
                                                        return event.preventDefault();
                                                      }
                                                    }}
                                                  />
                                                </Form.Item>
                                              )}
                                            </Form.Item>
                                            <Form.Item
                                              {...field}
                                              label="Price"
                                              name={[field.name, "price"]}
                                            >
                                              <Input
                                                type="number"
                                                min={0}
                                                initialValue={0}
                                                placeholder="Enter price for adults"
                                                style={{ marginBottom: 20 }}
                                                onKeyPress={(event) => {
                                                  if (
                                                    event.key.match("[0-9]+")
                                                  ) {
                                                    return true;
                                                  } else {
                                                    return event.preventDefault();
                                                  }
                                                }}
                                              />
                                            </Form.Item>

                                            <MinusCircleOutlined
                                              onClick={() => remove(field.name)}
                                            />
                                          </div>
                                        ))}
                                        <Form.Item className="add_adult_rate_btn">
                                          <Button
                                            type="dashed"
                                            onClick={() => add()}
                                            block
                                            icon={<PlusOutlined />}
                                            style={{ marginBottom: 14 }}
                                          >
                                            Add more occupancy rate
                                          </Button>
                                        </Form.Item>
                                      </>
                                    )}
                                  </Form.List> */}

                                    <div className="extra_rate_fields">
                                      <Form.Item
                                        label="Extra Adult Rate"
                                        name="extra_adult_rate"
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          placeholder="Enter extra adult rate"
                                          initialValue={0}
                                          style={{ marginBottom: 10 }}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Extra Child Rate"
                                        name="extra_child_rate"
                                      >
                                        <Input
                                          type="number"
                                          placeholder="Enter extra children rate"
                                          min={0}
                                          initialValue={0}
                                          style={{ marginBottom: 10 }}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label="Extra Infant Rate"
                                        name="extra_infant_rate"
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          placeholder="Enter extra infant rate"
                                          initialValue={0}
                                          style={{ marginBottom: 10 }}
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                    </div>

                                    {getItem("booking_app_enable") && (
                                      <Form.Item
                                        name="show_booking_engine"
                                        valuePropName="checked"
                                        initialValue={true}
                                      >
                                        <Checkbox
                                          className="add-form-check"
                                          style={{ marginTop: 10 }}
                                        >
                                          Show Rate Plan On Booking Engine.
                                        </Checkbox>
                                      </Form.Item>
                                    )}

                                    {extraRateList &&
                                    extraRateList.length > 0 ? (
                                      <div className="extraRateContainer">
                                        <br></br>
                                        <h3>Rate List</h3>
                                        <div className="extraRateContent">
                                          {extraRateList?.map((data, i) => {
                                            return (
                                              <div className="extraRateDetail">
                                                <div className="detail-header">
                                                  <h4
                                                    style={{ fontWeight: 600 }}
                                                  >
                                                    {data?.rate_name}
                                                  </h4>
                                                  <div className="actions">
                                                    <EditOutlined
                                                      onClick={() => {
                                                        setEdit(true);
                                                        setEditIndex(i);
                                                        showAddRateModal(data);
                                                      }}
                                                    />
                                                    <DeleteOutlined
                                                      onClick={() =>
                                                        confirmDelete(
                                                          i,
                                                          data?.rate_name
                                                        )
                                                      }
                                                    />
                                                  </div>
                                                </div>
                                                <Descriptions
                                                  bordered
                                                  size="small"
                                                  labelStyle={{
                                                    width: "300px",
                                                  }}
                                                >
                                                  <Descriptions.Item
                                                    label="Default Adult Rate"
                                                    span={3}
                                                  >
                                                    {data?.default_adult_rate}
                                                  </Descriptions.Item>

                                                  {data?.adult_rates?.map(
                                                    (h, f) => {
                                                      if (f) {
                                                        return (
                                                          <Descriptions.Item
                                                            label={getOccupancyLabel(
                                                              f
                                                            )}
                                                            span={3}
                                                          >
                                                            {h?.rate}
                                                          </Descriptions.Item>
                                                        );
                                                      }
                                                    }
                                                  )}

                                                  <Descriptions.Item
                                                    label="Extra adult Rate"
                                                    span={3}
                                                  >
                                                    {data?.extra_adult_rate}
                                                  </Descriptions.Item>
                                                  <Descriptions.Item
                                                    label="Extra Child Rate"
                                                    span={3}
                                                  >
                                                    {data?.extra_child_rate}
                                                  </Descriptions.Item>
                                                  <Descriptions.Item
                                                    label="Extra Infant Rate"
                                                    span={3}
                                                  >
                                                    {data?.extra_infant_rate}
                                                  </Descriptions.Item>
                                                  {currentRegisterData?.channel_key !=
                                                    "" &&
                                                    currentRegisterData?.connect_channel &&
                                                    data?.channel_rate_obj
                                                      ?.RatePlanName &&
                                                    roomTypeDetils?._id && (
                                                      <Descriptions.Item
                                                        label="Default ota room rate"
                                                        span={3}
                                                      >
                                                        {
                                                          data?.channel_rate_obj
                                                            ?.RatePlanName
                                                        }
                                                      </Descriptions.Item>
                                                    )}
                                                  {currentRegisterData?.channel_key !=
                                                    "" &&
                                                    currentRegisterData?.connect_channel &&
                                                    roomTypeDetils?._id &&
                                                    data?.adult_rates?.map(
                                                      (h, f) => {
                                                        if (
                                                          f &&
                                                          h.channel_rate_obj
                                                        ) {
                                                          return (
                                                            <Descriptions.Item
                                                              label={getOtaLabel(
                                                                f
                                                              )}
                                                              span={3}
                                                            >
                                                              {
                                                                h
                                                                  ?.channel_rate_obj
                                                                  ?.RatePlanName
                                                              }
                                                            </Descriptions.Item>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </Descriptions>
                                              </div>
                                            );
                                          })}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <div
                                      className="add-form-action"
                                      style={{ margin: "10px 0px 2px 0px" }}
                                    >
                                      <Form.Item>
                                        <NavLink to="/room-type">
                                          <Button
                                            className="btn-cancel btn-custom"
                                            style={{ background: "white" }}
                                          >
                                            Go Back
                                          </Button>
                                        </NavLink>
                                        <Button
                                          size="small"
                                          className="btn-custom"
                                          type="primary"
                                          raised
                                          style={{ marginRight: "20px" }}
                                          onClick={showAddRateModal}
                                        >
                                          <PlusOutlined /> Add More Rate Plan
                                        </Button>
                                        <Button
                                          size="small"
                                          className="btn-custom"
                                          type="primary"
                                          raised
                                          onClick={() => validateFields("3")}
                                        >
                                          Next
                                        </Button>
                                      </Form.Item>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </TabPane>
                          <TabPane tab="Additional Details" key="3">
                            {loader ? (
                              <Spin
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              />
                            ) : (
                              <div className="add-product-block">
                                <Row gutter={15}>
                                  <Col xs={24}>
                                    <div className="add-product-content">
                                      <Form.Item
                                        label="Number of Bed"
                                        name="no_beds"
                                        rules={[
                                          {
                                            required: true,
                                            message:
                                              "number of bed is required",
                                          },
                                        ]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          initialValue={0}
                                          style={{ marginBottom: 20 }}
                                          placeholder="Number of Bed"
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                      {/* <div className="max_number_fields">
                                      
                                      </div> */}
                                      <Form.Item
                                        name="summary_of_roomtype"
                                        label={
                                          <span>
                                            Summary of Room Type&nbsp;&nbsp;
                                            <Tooltip
                                              title="Use this fields to Add summary for your room type"
                                              color="#FFFF"
                                            >
                                              <InfoCircleFilled
                                                style={{ color: "#AD005A" }}
                                              />
                                            </Tooltip>
                                          </span>
                                        }
                                      >
                                        <TextArea
                                          style={{ marginBottom: 10 }}
                                          placeholder="Enter summary for room type"
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        name="room_ammenties"
                                        label={
                                          <span>
                                            Room ammenties&nbsp;&nbsp;
                                            <Tooltip
                                              title="Use this fields to Add summary for your room type"
                                              color="#FFFF"
                                            >
                                              <InfoCircleFilled
                                                style={{ color: "#AD005A" }}
                                              />
                                            </Tooltip>
                                          </span>
                                        }
                                      >
                                        <Select
                                          mode="multiple"
                                          style={{
                                            width: "100%",
                                            marginBottom: 10,
                                          }}
                                          showSearch
                                          filterOption={(input, option) =>
                                            option.children
                                              .toLowerCase()
                                              .indexOf(input.toLowerCase()) >= 0
                                          }
                                          placeholder="Please select room ammenties"
                                        >
                                          {amenities.map((val, index) => (
                                            <Select.Option
                                              key={index}
                                              value={val}
                                            >
                                              {val}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>

                                      {fileList && roomTypeDetils?._id && (
                                        <Form.Item label="Room image">
                                          <Upload
                                            listType="picture-card"
                                            customRequest={dummyRequest}
                                            fileList={fileList}
                                            onPreview={handlePreview}
                                            onChange={handleChange1}
                                          >
                                            {uploadButton}
                                          </Upload>

                                          {previewImage && (
                                            <Image
                                              wrapperStyle={{ display: "none" }}
                                              preview={{
                                                visible: previewOpen,
                                                onVisibleChange: (visible) =>
                                                  setPreviewOpen(visible),
                                                afterOpenChange: (visible) =>
                                                  !visible &&
                                                  setPreviewImage(""),
                                              }}
                                              src={previewImage}
                                            />
                                          )}
                                        </Form.Item>
                                      )}
                                      <div
                                        className="add-form-action"
                                        style={{ margin: "10px 0px 2px 0px" }}
                                      >
                                        <Form.Item>
                                          <NavLink to="/room-type">
                                            <Button
                                              className="btn-cancel btn-custom"
                                              style={{
                                                background: "white",
                                              }}
                                            >
                                              Go Back
                                            </Button>
                                          </NavLink>

                                          <Button
                                            size="small"
                                            className="btn-custom"
                                            htmlType="submit"
                                            type="primary"
                                            raised
                                          >
                                            {loading ? (
                                              <Spin
                                                indicator={
                                                  <LoadingOutlined
                                                    style={{
                                                      fontSize: 16,
                                                      color: "white",
                                                      margin: "0px 8px",
                                                    }}
                                                    spin
                                                  />
                                                }
                                              />
                                            ) : (
                                              "Save"
                                            )}
                                          </Button>
                                        </Form.Item>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            )}
                          </TabPane>
                        </Tabs>
                      </BasicFormWrapper>
                    </Form>
                  </AddProductForm>
                </Col>
              </Row>
            </Cards>

            <Modal
              title="Add More Rate Plan"
              open={openAddModal}
              onOk={addForm.submit}
              onCancel={() => {
                setOpenAddModal(false);
                setEditIndex();
                setEdit(false);
              }}
            >
              <div className="addRatePlanForm">
                <Form
                  style={{ width: "100%" }}
                  form={addForm}
                  name="addNewRate"
                  onFinish={submitAddRate}
                  className="comman-input"
                >
                  <BasicFormWrapper>
                    <Row gutter={15}>
                      <Col span={24}>
                        <Form.Item
                          label="Rate Name"
                          name="rate_name"
                          rules={[
                            {
                              required: true,
                              message: "Product name is required",
                            },
                          ]}
                        >
                          <Input
                            style={{ marginBottom: 10 }}
                            placeholder="Rate Name"
                          />
                        </Form.Item>
                        {currentRegisterData?.channel_key != "" &&
                        currentRegisterData?.connect_channel &&
                        roomTypeDetils?._id ? (
                          <div>
                            <Form.Item
                              label={`Default Adult Rate (for ${adultNo})`}
                              name="default_adult_rate"
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 12px)",
                              }}
                              rules={[
                                {
                                  required: true,
                                  message: "default adult rate is required",
                                },
                              ]}
                            >
                              <Input
                                type="number"
                                min={0}
                                initialValue={0}
                                style={{
                                  marginBottom: 20,
                                }}
                                placeholder="Default Adult Rate"
                                onKeyPress={(event) => {
                                  if (event.key.match("[0-9]+")) {
                                    return true;
                                  } else {
                                    return event.preventDefault();
                                  }
                                }}
                              />
                            </Form.Item>
                            <span
                              style={{
                                display: "inline-block",
                                width: "12px",
                                lineHeight: "32px",
                                textAlign: "center",
                              }}
                            ></span>{" "}
                            <Form.Item
                              name="channel_rate_code"
                              label="Default ota room rate"
                              style={{
                                display: "inline-block",
                                width: "calc(50% - 12px)",
                              }}
                            >
                              <Select
                                placeholder="Select ota room rate"
                                showSearch
                                style={{ marginBottom: 10 }}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {channelRatePlans.map((taxGrp) => (
                                  <option
                                    key={taxGrp.RatePlanCode}
                                    value={taxGrp.RatePlanCode}
                                  >
                                    {taxGrp.RatePlanName}
                                  </option>
                                ))}
                              </Select>
                            </Form.Item>
                          </div>
                        ) : (
                          <Form.Item
                            label={`Default Adult Rate (for ${adultNo})`}
                            name="default_adult_rate"
                            rules={[
                              {
                                required: true,
                                message: "default adult rate is required",
                              },
                            ]}
                          >
                            <Input
                              type="number"
                              min={0}
                              initialValue={0}
                              style={{
                                marginBottom: 20,
                                width: "97%",
                                marginRight: "10px",
                              }}
                              placeholder="Default Adult Rate"
                              onKeyPress={(event) => {
                                if (event.key.match("[0-9]+")) {
                                  return true;
                                } else {
                                  return event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        )}
                        {currentRegisterData?.channel_key != "" &&
                        currentRegisterData?.connect_channel &&
                        roomTypeDetils?._id
                          ? Array.from({ length: adultNo - 1 }, (_, index) => (
                              <div>
                                <Form.Item
                                  key={index}
                                  label={getOccupancyLabel(index + 1)} // Set dynamic label
                                  name={`adult_${index + 1}_rate`}
                                  style={{
                                    display: "inline-block",
                                    width: "calc(50% - 12px)",
                                  }}
                                  rules={[
                                    {
                                      required: true,
                                      message: `Rate for ${getOccupancyLabel(
                                        index + 1
                                      ).toLowerCase()} is required`,
                                    },
                                  ]}
                                >
                                  <Input
                                    type="number"
                                    min={0}
                                    placeholder={`Enter rate for ${index +
                                      1} adult(s)`}
                                    style={{
                                      marginBottom: 20,
                                      width: "100%",
                                    }}
                                    onKeyPress={(event) => {
                                      if (event.key.match("[0-9]+")) {
                                        return true;
                                      } else {
                                        return event.preventDefault();
                                      }
                                    }}
                                  />
                                </Form.Item>
                                <span
                                  style={{
                                    display: "inline-block",
                                    width: "12px",
                                    lineHeight: "32px",
                                    textAlign: "center",
                                  }}
                                ></span>
                                <Form.Item
                                  name={`channel_rate_code${index + 1}`}
                                  label={getOtaLabel(index + 1)}
                                  style={{
                                    display: "inline-block",
                                    width: "calc(50% - 12px)",
                                  }}
                                >
                                  <Select
                                    placeholder="Select ota room rate"
                                    showSearch
                                    style={{ marginBottom: 10 }}
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                  >
                                    {channelRatePlans.map((taxGrp) => (
                                      <option
                                        key={taxGrp.RatePlanCode}
                                        value={taxGrp.RatePlanCode}
                                      >
                                        {taxGrp.RatePlanName}
                                      </option>
                                    ))}
                                  </Select>
                                </Form.Item>
                              </div>
                            ))
                          : Array.from({ length: adultNo - 1 }, (_, index) => (
                              <Form.Item
                                key={index}
                                label={getOccupancyLabel(index + 1)} // Set dynamic label
                                name={`adult_${index + 1}_rate`}
                                rules={[
                                  {
                                    required: true,
                                    message: `Rate for ${getOccupancyLabel(
                                      index + 1
                                    ).toLowerCase()} is required`,
                                  },
                                ]}
                              >
                                <Input
                                  type="number"
                                  min={0}
                                  placeholder={`Enter rate for ${index +
                                    1} adult(s)`}
                                  style={{
                                    marginBottom: 20,
                                    width: "100%",
                                  }}
                                  onKeyPress={(event) => {
                                    if (event.key.match("[0-9]+")) {
                                      return true;
                                    } else {
                                      return event.preventDefault();
                                    }
                                  }}
                                />
                              </Form.Item>
                            ))}

                        {/* {currentRegisterData?.channel_key != "" &&
                          currentRegisterData?.connect_channel && (
                            <Form.Item
                              name="channel_rate_code"
                              label="Ota room rate"
                            >
                              <Select
                                placeholder="Select Ota room rate"
                                showSearch
                                style={{ marginBottom: 10 }}
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                                }
                              >
                                {channelRatePlans.map((taxGrp) => (
                                  <option
                                    key={taxGrp.RatePlanCode}
                                    value={taxGrp.RatePlanCode}
                                  >
                                    {taxGrp.RatePlanName}
                                  </option>
                                ))}
                              </Select>
                            </Form.Item>
                          )} */}

                        {/* </div> */}
                        {/* <Form.List name="adult_rate_plan">
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map((field) => (
                                <div className="ratePlanSpace">
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, curValues) =>
                                      prevValues.area !== curValues.area ||
                                      prevValues.sights !== curValues.sights
                                    }
                                  >
                                    {() => (
                                      <Form.Item
                                        {...field}
                                        label="Number of Adults"
                                        name={[field.name, "no_adults"]}
                                      >
                                        <Input
                                          type="number"
                                          min={0}
                                          initialValue={0}
                                          style={{ marginBottom: 20 }}
                                          placeholder="Number of Adults"
                                          onKeyPress={(event) => {
                                            if (event.key.match("[0-9]+")) {
                                              return true;
                                            } else {
                                              return event.preventDefault();
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                    )}
                                  </Form.Item>
                                  <Form.Item
                                    {...field}
                                    label="Price"
                                    name={[field.name, "price"]}
                                  >
                                    <Input
                                      type="number"
                                      min={0}
                                      initialValue={0}
                                      style={{ marginBottom: 20 }}
                                      placeholder="Price"
                                      onKeyPress={(event) => {
                                        if (event.key.match("[0-9]+")) {
                                          return true;
                                        } else {
                                          return event.preventDefault();
                                        }
                                      }}
                                    />
                                  </Form.Item>

                                  <MinusCircleOutlined
                                    onClick={() => remove(field.name)}
                                  />
                                </div>
                              ))}
                              <Form.Item className="add_adult_rate_btn">
                                <Button
                                  type="dashed"
                                  onClick={() => add()}
                                  block
                                  icon={<PlusOutlined />}
                                  style={{ marginBottom: 14 }}
                                >
                                  Add more occupancy rate
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List> */}

                        <div className="extra_rate_fields">
                          <Form.Item
                            label="Extra Adult Rate"
                            name="extra_adult_rate"
                          >
                            <Input
                              type="number"
                              min={0}
                              initialValue={0}
                              style={{ marginBottom: 10 }}
                              placeholder="Extra Adult Rate"
                              onKeyPress={(event) => {
                                if (event.key.match("[0-9]+")) {
                                  return true;
                                } else {
                                  return event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Extra Child Rate"
                            name="extra_child_rate"
                          >
                            <Input
                              type="number"
                              min={0}
                              initialValue={0}
                              style={{ marginBottom: 10 }}
                              placeholder="Extra Child Rate"
                              onKeyPress={(event) => {
                                if (event.key.match("[0-9]+")) {
                                  return true;
                                } else {
                                  return event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                          <Form.Item
                            label="Extra Infant Rate"
                            name="extra_infant_rate"
                          >
                            <Input
                              type="number"
                              min={0}
                              initialValue={0}
                              style={{ marginBottom: 10 }}
                              placeholder="Extra Infant Rate"
                              onKeyPress={(event) => {
                                if (event.key.match("[0-9]+")) {
                                  return true;
                                } else {
                                  return event.preventDefault();
                                }
                              }}
                            />
                          </Form.Item>
                        </div>
                        {getItem("booking_app_enable") && (
                          <Form.Item
                            name="show_booking_engine"
                            valuePropName="checked"
                            initialValue={true}
                          >
                            <Checkbox
                              className="add-form-check"
                              style={{ marginTop: 10 }}
                            >
                              Show Rate Plan On Booking Engine.
                            </Checkbox>
                          </Form.Item>
                        )}
                      </Col>
                    </Row>
                  </BasicFormWrapper>
                </Form>
              </div>
            </Modal>
          </Col>
        </Row>
      </Main>
    </>
  );
};

export default AddEditRoomType;
