import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import {
  Modal,
  Tabs,
  Form,
  Input,
  Tag,
  Row,
  Radio,
  Button,
  Col,
  message,
  AutoComplete,
  Select,
  DatePicker,
  Table,
  Spin,
  Upload,
  Image,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;
import {
  customerFilter,
  getSingleReservation,
} from "../../../redux/reservation/actionCreator";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import list from "./countryList";
import { getItem } from "../../../utility/localStorageControl";
import commonFunction from "../../../utility/commonFunctions";
import { PlusOutlined } from "@ant-design/icons";
const RoomCustomer = (props) => {
  let {
    customerModalVisible,
    setCustomerModalVisible,
    roomDetails,
    handleSingleCustomerAddUpdate,
    loader,
    reservationData,
    reservationCustomerUpdate,
  } = props;
  const [roomList, setRoomList] = useState([]);
  const dispatch = useDispatch();
  const [customerList, setCustomerList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [issuedDate, setIssuedDate] = useState(moment().format("LL"));
  const [expiringDate, setExpiringDate] = useState(moment().format("LL"));
  const [customerid, setCustomerId] = useState();
  const [lastreservationLoader, setLastReservationOrder] = useState(false);
  const [lastReservationData, setLastReservationData] = useState();
  const [customerDetails, setCustomerDetails] = useState();
  const [windowWidth, setWindowWidth] = useState(
    window.innerWidth > 0 ? window.innerWidth : window.outerWidth
  );
  const [fileList, setFileList] = useState([]);
  let localData = getItem("setupCache");
  const [rsSymbol, setRsSymbol] = useState(
    localData?.shopDetails?.rs_symbol
      ? /\(([^)]+)\)/.exec(localData.shopDetails.rs_symbol)?.length > 0
        ? /\(([^)]+)\)/.exec(localData.shopDetails.rs_symbol)[1]
        : localData.shopDetails.rs_symbol
      : "₹"
  );
  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  const [currentTab, setCurrentTab] = useState("General");
  const cols = [
    {
      title: "Room",
      dataIndex: "room_name",
      key: "room_name",
      fixed: "left",
      render(text, value, index) {
        return {
          children: (
            <div
              style={value?.cancel ? { textDecoration: "line-through" } : {}}
            >
              {value.paid_room
                ? value.paid_room
                : `${value?.room_type?.name} ${text}`}
            </div>
          ),
        };
      },
    },

    {
      title: "Rate Plan",
      dataIndex: "rate_plan",
      key: "rate_plan",
      render(text, value) {
        return {
          children: (
            <div
              style={value?.cancel ? { textDecoration: "line-through" } : {}}
            >
              {text?.rate_name}
            </div>
          ),
        };
      },
    },

    {
      title: "Price",
      dataIndex: "calculatedPriceWithoutTax",
      key: "calculatedPriceWithoutTax",
      align: "left",
      render(text, value) {
        return {
          children: (
            <div
              style={value?.cancel ? { textDecoration: "line-through" } : {}}
            >
              {rsSymbol}
              {Number(
                value.type == "room" ? value.calculatedPriceWithTax : text
              ).toFixed(2)}
            </div>
          ),
        };
      },
    },
    {
      title: "Tax",
      dataIndex: "calCulatedTaxPrice",
      key: "calCulatedTaxPrice",
      align: "left",
      render(text, value) {
        return {
          children: (
            <div
              style={value?.cancel ? { textDecoration: "line-through" } : {}}
            >
              {rsSymbol}
              {Number(
                value.type == "room" ? value.calCulatedTaxPrice : text
              ).toFixed(2)}
            </div>
          ),
        };
      },
    },
    ,
  ];
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    // window.open(file.url || file.preview);
    // printWindow.print();
  };
  const handleChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const fetchCustomerSearchList = async (val) => {
    const response = await dispatch(customerFilter({ searchText: val }));

    if (response && response.data.length) {
      message.success({
        content: "Customer is match",
        style: {
          float: "right",
          marginTop: "2vh",
        },
      });
      setCustomerList(response.data);
    } else {
      setCustomerList([]);
      message.error({
        content: "Customer is not match",
        style: {
          float: "right",
          marginTop: "2vh",
        },
      });
    }
  };
  const searchDidmount = useRef();
  const [debounceTimer, setDebounceTimer] = useState(null);
  useEffect(() => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }
    const timer = setTimeout(() => {
      if (searchDidmount.current && searchValue != "") {
        fetchCustomerSearchList(searchValue);
      } else if (searchValue == "") {
        searchDidmount.current = true;
      } else {
        searchDidmount.current = true;
      }
    }, 500);
    setDebounceTimer(timer);
    return () => clearTimeout(timer);
  }, [searchValue]);
  const handleSubmitBasicDetails = (val) => {
    console.log("gdgagdjagadaada", customerModalVisible, val);
    if (issuedDate) {
      val["issued_at"] = issuedDate;
    }
    if (expiringDate) {
      val["expiring_at"] = expiringDate;
    }
    val["doc_images"] = fileList;
    console.log("customerId", customerid);
    if (customerModalVisible.type == "room") {
      handleSingleCustomerAddUpdate({
        ...val,
        _id: customerid,
      });
    } else {
      reservationCustomerUpdate("customer", {
        ...val,
        _id: customerid ? customerid : reservationData?.customerId._id,
      });
    }
  };

  const handleCancel = () => {
    setCustomerModalVisible({
      visible: false,
      type: "room",
    });
  };
  const [addForm] = Form.useForm();
  const hanldSelectCustomer = (value, option) => {
    const find = customerList.find((j) => j._id == option.key);
    if (find) {
      if (find.doc_images && find.doc_images.length > 0) {
        setFileList(find.doc_images);
      }
      if (find.issued_at) {
        setIssuedDate(moment(find.issued_at).format("LL"));
        delete find.issued_at;
      }
      if (find.expiring_at) {
        setExpiringDate(moment(find.expiring_at).format("LL"));
        delete find.expiring_at;
      }
      addForm.setFieldsValue(find);
      setCustomerDetails(find);
      setCustomerId(find._id);
    } else {
      setCustomerId();
    }
  };
  useEffect(() => {
    if (roomDetails.customerInfo) {
      let formData =
        customerModalVisible.type == "room"
          ? roomDetails.customerInfo
          : reservationData?.customerId;

      if (formData.issued_at) {
        moment(location?.state?.billDetails?.invoice_date).format("LL");
        setIssuedDate(moment(formData.issued_at).format("LL"));
        delete formData.issued_at;
      }
      if (formData.expiring_at) {
        setExpiringDate(moment(formData.expiring_at).format("LL"));
        delete formData.expiring_at;
      }
      if (roomDetails?.customerInfo?._id) {
        setCustomerId(roomDetails?.customerInfo?._id);
      }

      if (formData.doc_images && formData.doc_images.length > 0) {
        setFileList(formData.doc_images);
      }
      addForm.setFieldsValue(formData);
      setCustomerDetails(formData);
    } else {
    }
  }, []);
  const getLastReservationData = async () => {
    if (customerDetails?.recent_reservation?.length > 0) {
      let { reservation_id } = customerDetails?.recent_reservation[
        customerDetails?.recent_reservation.length - 1
      ];
      if (reservation_id) {
        setLastReservationOrder(true);
        let response = await dispatch(getSingleReservation(reservation_id));
        if (response && response.data) {
          setLastReservationData(response.data);
          setRoomList(response?.data?.roomInfo);
        }
        setLastReservationOrder(false);
      } else {
        setLastReservationData("not found");
      }
    } else {
      setLastReservationData("not found");
    }
  };

  useEffect(() => {
    if (currentTab == "History" && customerDetails) {
      getLastReservationData();
    }
  }, [currentTab]);
  const handleAdditionalAction = () => {
    addForm.resetFields();
    setCustomerDetails();
    setCustomerId();
  };
  const dummyRequest = ({ file, onSuccess }) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  return (
    <Modal
      title="Add Guest"
      open={customerModalVisible.visible}
      width={600}
      closeIcon={<CloseOutlined onClick={() => handleCancel()} />}
      footer={[
        <Button key="cancel" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="additional" onClick={handleAdditionalAction}>
          New Customer
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loader}
          onClick={addForm.submit}
        >
          {loader ? "Loading..." : "Save"}
        </Button>,
      ]}
    >
      <Form
        style={{ width: "100%" }}
        form={addForm}
        name="addNewGuest"
        onFinish={handleSubmitBasicDetails}
      >
        <Tabs defaultActiveKey="General" onChange={(val) => setCurrentTab(val)}>
          <TabPane tab="General" key="General">
            <Form.Item label="Customer Search" name="clear">
              <AutoComplete
                className="CustomerAutoComplate"
                style={{ width: "100%", marginBottom: "10px" }}
                options={customerList.map((option) => ({
                  value: option.mobile ? option.mobile : option.name,
                  key: option._id,
                  label: (
                    <div>
                      {option.name && <span>{option.name}</span>}
                      {option.mobile && <span> , {option.mobile}</span>}
                      {option.email && <span> , {option.email}</span>}
                    </div>
                  ),
                }))}
                onSelect={(val, option) => {
                  hanldSelectCustomer(val, option);
                }}
                onSearch={(value) => setSearchValue(value)}
                placeholder="Search by customer name , mobile , email"
              ></AutoComplete>
            </Form.Item>

            <Form.Item
              name="name"
              label="Cutomer Name"
              rules={[
                {
                  message: "Please enter customer name",
                  required: true,
                },
              ]}
            >
              <Input
                style={{
                  marginBottom: "10px",
                }}
                placeholder="Customer Name"
              />
            </Form.Item>

            <Form.Item name="mobile" label="Customer Mobile">
              <Input
                type="number"
                style={{
                  marginBottom: "10px",
                }}
                placeholder="Customer mobile"
                onKeyPress={(event) => {
                  if (event.key.match("[0-9]+")) {
                    return true;
                  } else {
                    return event.preventDefault();
                  }
                }}
              />
            </Form.Item>

            <Form.Item name="email" label="Customer Email">
              <Input
                type="email"
                placeholder="Customer email"
                style={{
                  marginBottom: "10px",
                }}
              />
            </Form.Item>

            <Form.Item name="shipping_address" label="Customer Address">
              <TextArea
                placeholder="Customer address"
                style={{
                  marginBottom: "10px",
                }}
              />
            </Form.Item>
          </TabPane>

          <TabPane tab="Identity" key="Identity">
            <Form.Item name="document_type" label="Document Type">
              <Select
                size="small"
                style={{ marginBottom: "10px" }}
                placeholder="Select a country"
              >
                <Option value="adhar_card">Adhar Card</Option>
                <Option value="passport">Passport</Option>
                <Option value="driving_licence">Driving Licence</Option>
                <Option value="voter_id">Voter ID</Option>
                <Option value="company_id">Company ID</Option>
                <Option value="national_id">National ID</Option>
              </Select>
            </Form.Item>

            <Form.Item
              name="identification_number"
              label="Identification Number"
            >
              <Input
                placeholder="Enter identification number"
                style={{ marginBottom: "10px" }}
              />
            </Form.Item>

            <Form.Item name="document_issued_by" label="Document issued by">
              <Select
                size="small"
                style={{ marginBottom: "10px", width: "100%" }}
                placeholder="Select a country"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {list.map((val, index) => {
                  return (
                    <Option value={val.name} key={index}>
                      {val.name}
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item
              label="Issued at"
              name="issued_at"
              initialValue={moment(issuedDate, "LL")}
              style={{
                display: "inline-block",
                width: "calc(50% - 12px)",
              }}
            >
              <DatePicker
                style={{ minWidth: "100%", marginBottom: "10px" }}
                format="LL"
                onChange={(date, string) => {
                  setIssuedDate(string);
                }}
              />
            </Form.Item>
            <span
              style={{
                display: "inline-block",
                width: "24px",
                lineHeight: "32px",
                textAlign: "center",
              }}
            ></span>
            <Form.Item
              label="Expiring at"
              name="expiring_at"
              style={{
                display: "inline-block",
                width: "calc(50% - 12px)",
              }}
              initialValue={moment(expiringDate, "LL")}
            >
              <DatePicker
                style={{ minWidth: "100%", marginBottom: "10px" }}
                format="LL"
                onChange={(date, string) => {
                  setExpiringDate(string);
                }}
              />
            </Form.Item>

            {fileList && (
              <Form.Item label="Upload Document">
                <Upload
                  listType="picture-card"
                  customRequest={dummyRequest}
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChange}
                >
                  {uploadButton}
                </Upload>
                {previewImage && (
                  <Image
                    wrapperStyle={{ display: "none" }}
                    preview={{
                      visible: previewOpen,
                      onVisibleChange: (visible) => setPreviewOpen(visible),
                      afterOpenChange: (visible) =>
                        !visible && setPreviewImage(""),
                    }}
                    src={previewImage}
                  />
                )}
              </Form.Item>
            )}
          </TabPane>
          <TabPane tab="Company" key="Company">
            <Form.Item label="Company Name" name="company_name">
              <Input
                style={{ marginBottom: "10px" }}
                placeholder="Enter company number"
              />
            </Form.Item>
            <Form.Item name="GSTIN_number" label="Tax Number">
              <Input
                placeholder="Enter Tax Number"
                style={{ marginBottom: "10px" }}
              />
            </Form.Item>
            <Form.Item name="company_address" label="Address">
              <TextArea
                placeholder="Enter address"
                style={{ marginBottom: "10px", width: "100%" }}
              />
            </Form.Item>
          </TabPane>
          <TabPane tab="History" key="History">
            {lastreservationLoader ? (
              <Spin
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            ) : (
              <div>
                {lastReservationData && lastReservationData != "not found" ? (
                  <div>
                    <Row>
                      <Form.Item
                        className="tvisit-n l-h0"
                        name="totalounts"
                        label={
                          "Total Reservation Count  " +
                          customerDetails?.reservation_count
                        }
                      ></Form.Item>
                      <Form.Item
                        className="l-h0"
                        name="totalounts"
                        label={`Last Visit at ${commonFunction.convertToDate(
                          lastReservationData?.reservationUpdateTime,
                          "MMM DD, Y, h:mm A"
                        )}`}
                      ></Form.Item>
                    </Row>
                    <br></br>
                    <Table
                      rowKey="_id"
                      className={"tbl_data handleScroll-normal reservation"}
                      dataSource={roomList}
                      columns={cols}
                      size="small"
                      scroll={windowWidth <= 600 ? { x: 600 } : {}}
                      pagination={false}
                    />
                  </div>
                ) : (
                  <div>
                    <h3>Not any last reservation </h3>
                  </div>
                )}{" "}
              </div>
            )}
          </TabPane>
        </Tabs>
      </Form>
    </Modal>
  );
};

export default React.memo(RoomCustomer);
