import React, { useState, useEffect, useRef } from "react";
import { NavLink, useRouteMatch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Input, Modal, Space, Button, Form, Spin, Tooltip, message } from "antd";
import { SearchOutlined, ImportOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import { UserTableStyleWrapper } from "../../pages/style";
import FeatherIcon from "feather-icons-react";
import { CardToolbox } from "../../Customer/Style";
import { PageHeader } from "../../../components/page-headers/page-headers";
import { Main, TableWrapper } from "../../styled";
import { Cards } from "../../../components/cards/frame/cards-frame";
import { LoadingOutlined } from "@ant-design/icons";
import { Popover } from "../../../components/popup/popup";
import { getCustomerList, filterListData, ExportCustomer } from "../../../redux/customer/actionCreator";
import commonFunction from "../../../utility/commonFunctions";
import { useHistory } from "react-router-dom";
import "../../Customer/customer.css";
import { getItem, setItem } from "../../../utility/localStorageControl";
import { getMasterList, getReservationOutstandingList, getRoomOcccupancy } from "../../../redux/HotelReports/actionCreator";
import moment from "moment";
import { CalendarButtonPageHeader } from "../../../components/buttons/calendar-button/calendar-button";
import { handlePaymnetModeDate } from "../../../redux/receipts/actionCreator";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";

const Outstanding = () => {
    const { path } = useRouteMatch();
    let location = useLocation();
    const dispatch = useDispatch();
    const [sizeOfData, setSize] = useState(10);
    let searchInput = useRef(null);
    const history = useHistory();
    const [form] = Form.useForm();
    const [changePage, setChangePage] = useState(1);
    const [totalCustomer, setTotalCustomer] = useState();
    const [CustomerListData, setCustomerListData] = useState([]);
    const [roomReservation, setRoomReservation] = useState([]);
    const [exportType, setExportType] = useState();
    const [loading, setLoading] = useState(false);
    let isMounted = useRef(true);
    const [modalVisible, setModelVisible] = useState(false);
    const [loader, setLoader] = useState(true);
    const [reservationLoading, setReservationLoading] = useState({
        id: "",
        loading: false,
    });
    const csvLinkRef = useRef();
    const { startDate, endDate, dateChange, currentRegisterData } = useSelector((state) => {
        return {
            startDate: state.receipts.paymnetModeStartDate
                ? state.receipts.paymnetModeStartDate
                : moment()
                      .startOf("month")
                      .format("YYYY-MM-DD"),
            endDate: state.receipts.paymnetModeEndDate
                ? state.receipts.paymnetModeEndDate
                : moment()
                      .endOf("month")
                      .format("YYYY-MM-DD"),
            dateChange: state.receipts.paymnetModeDateChange,
            currentRegisterData: state.register.RegisterList?.length > 0 && state.register.RegisterList?.find((val) => val.activeHotel),
        };
    });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);
    useEffect(() => {
        setWindowWidth(window.innerWidth > 0 ? window.innerWidth : window.outerWidth);
        setItem("windowWidth", windowWidth);
    }, [window.innerWidth > 0 ? window.innerWidth : window.outerWidth]);
    const deviceWidth = getItem("windowWidth");

    let searchText = "";
    async function fetchCustomerList(start, end) {
        setLoader(true);
        const response = await dispatch(getReservationOutstandingList(currentRegisterData?._id, start, end));
        setLoader(false);

        if (response && !response.error) {
            console.log("responseDataaaa", response?.data);
            setRoomReservation(response?.data);
        } else if (response) {
            message.error(response.message);
        }
    }
    const didMountRegister = useRef(false);
    useEffect(() => {
        if (didMountRegister.current) {
            if (currentRegisterData) {
                fetchCustomerList(startDate, endDate);
            }
        } else {
            didMountRegister.current = true;
        }
    }, [currentRegisterData]);
    async function setStartDateAndDate(startDate, endDate) {
        dispatch(handlePaymnetModeDate(startDate, endDate, false));
    }
    useEffect(() => {
        let startDate = moment()
            .startOf("month")
            .format("YYYY-MM-DD");
        let endDate = moment()
            .endOf("month")
            .format("YYYY-MM-DD");
        fetchCustomerList(startDate, endDate);
        setStartDateAndDate(startDate, endDate);
    }, []);

    const didMount = useRef(false);
    useEffect(() => {
        if (didMount.current) {
            if (dateChange) {
                fetchCustomerList(startDate, endDate);
            }
        } else {
            didMount.current = true;
        }
    }, [dateChange, startDate, endDate]);

    let email = localStorage.getItem("email_id");

    const onSubmit = async (values) => {
        setLoading(true);
        if (loading) {
            setModelVisible(false);
            setLoading(false);
        }
        values.type = exportType;
        let ExportCustomerAPI = await dispatch(ExportCustomer(values));
        // if (!ExportCustomerAPI.error) {
        //   setLoading(false);
        //   setModelVisible(false);
        // }
    };

    const handleCancel = (e) => {
        setModelVisible(false);
    };

    const content = (
        <>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadPdf();
                }}
            >
                <FeatherIcon size={16} icon="book-open" className="popupIcons" />
                <span>PDF</span>
            </NavLink>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadExcel();
                }}
            >
                <FeatherIcon size={16} icon="x" className="popupIcons" />
                <span>Excel (XLSX)</span>
            </NavLink>
            <NavLink
                to="#"
                onClick={() => {
                    handleDownloadCSV();
                }}
            >
                <FeatherIcon size={16} icon="file" className="popupIcons" />
                <span>CSV</span>
            </NavLink>
        </>
    );
    let locale = {
        emptyText: (
            <Spin
                style={{
                    marginTop: "20px",
                }}
            />
        ),
    };
    const handleReservation = async (id) => {
        history.push("/reservation", { reservationData: id });
    };
    const columns = [
        {
            title: "NAME",
            dataIndex: "customerId",
            key: "customerId",
            fixed: "left",
            render(text, record) {
                return {
                    children: <div>{text?.name}</div>,
                };
            },
        },
        {
            title: "REFERENCE",
            dataIndex: "reservationStringId",
            key: "reservationStringId",
            render(text, record) {
                return {
                    children: (
                        <div>
                            {reservationLoading?.id == record._id && reservationLoading.loading ? (
                                <Spin
                                    style={{
                                        marginTop: "10px",
                                        color: "#CA366E",
                                    }}
                                />
                            ) : (
                                <div style={{ color: "#008cba", display: "inline" }}>#{text}</div>
                            )}
                        </div>
                    ),
                };
            },
        },
        {
            title: "STATUS",
            dataIndex: "isCancelled",
            key: "isCancelled",
            align: "left",
            filters: [
                { text: "Confirmed", value: "Confirmed" },
                { text: "Completed", value: "Completed" },
            ],
            onFilter: (value, record) => (value === "Completed" ? record.isReceipt : !record.isReceipt),
            render(text, record) {
                return {
                    children: (
                        <p
                            style={{
                                backgroundColor: record.isReceipt ? "rgb(212, 243, 255)" : text ? "#ff4d4f10" : "rgb(240, 242, 242)",
                                borderRadius: "15px",
                                color: record.isReceipt ? "rgb(24, 101, 132)" : text ? "#ff4d4f" : "#393939",
                                display: "inline",
                                fontSize: "13px",
                                fontWeight: 500,
                                padding: "4.5px 11.85px",
                            }}
                        >
                            {record.isReceipt ? "Completed" : text ? "Cancelled" : "Confirmed"}
                        </p>
                    ),
                };
            },
        },
        {
            title: "SOURCE",
            dataIndex: "general",
            key: "general",
            align: "left",
            render: (text) => <span>{text?.source}</span>,
        },
        {
            title: "CHANNEL",
            dataIndex: "general",
            key: "general",
            align: "left",
            render: (text) => <span>{text?.channel}</span>,
        },
        {
            title: "ROOMS",
            dataIndex: "roomInfo",
            key: "roomInfo",
            align: "left",
            render: (text) => <span>{text?.length} room(s)</span>,
        },
        {
            title: "CHECK-IN",
            dataIndex: "reservationInDateAndTime",
            key: "reservationInDateAndTime",
            align: "left",
            render: (last_seen, record) => <span>{commonFunction.convertToDate(last_seen, "MMM DD, Y")}</span>,
        },
        {
            title: "CHECK-OUT",
            dataIndex: "reservationOutDateAndTime",
            key: "reservationOutDateAndTime",
            align: "left",
            render: (last_seen, record) => <span>{last_seen == "-" ? last_seen : commonFunction.convertToDate(last_seen, "MMM DD, Y")}</span>,
        },
        {
            title: "NUMBER OF NIGHTS",
            dataIndex: "totalNights",
            key: "totalNights",
            align: "left",
            render: (text) => <span>{text} night(s)</span>,
        },
        {
            title: "ROOM CHARGES",
            dataIndex: "payment",
            key: "payment",
            align: "left",
            render: (text) => (
                <span>
                    {text?.currency}
                    {text?.roomCharges}
                </span>
            ),
        },
        {
            title: "TAX",
            dataIndex: "payment",
            key: "payment",
            align: "left",
            render: (text) => (
                <span>
                    {text?.currency}
                    {text?.roomChargesTax}
                </span>
            ),
        },
        {
            title: "EXTRAS",
            dataIndex: "payment",
            key: "payment",
            align: "left",
            render: (text) => (
                <span>
                    {text?.currency}
                    {text?.extraCharges}
                </span>
            ),
        },
        {
            title: "TOTAL",
            dataIndex: "payment",
            key: "payment",
            align: "left",
            render: (text) => (
                <span>
                    {text?.currency}
                    {text?.grandTotal}
                </span>
            ),
        },
        {
            title: "PAYMENT RECEIVED",
            dataIndex: "payment",
            key: "payment",
            align: "left",
            render: (text) => (
                <span>
                    {text?.currency}
                    {text?.received}
                </span>
            ),
        },
        {
            title: "OUTSTANDING",
            dataIndex: "payment",
            key: "payment",
            align: "left",
            render: (text) => (
                <span>
                    {text?.currency}
                    {text?.grandTotal - text?.received}
                </span>
            ),
        },
        {
            title: "BOOKED ON",
            dataIndex: "reservationBookTime",
            key: "reservationBookTime",
            align: "left",
            render: (text) => <span>{commonFunction.convertToDate(text, "MMM DD, Y, h:mm A")}</span>,
        },
        {
            title: "BOOKED BY",
            dataIndex: "reservationCreatedStaffUserId",
            key: "reservationCreatedStaffUserId",
            align: "left",
            render: (text) => <span>{text?.username}</span>,
        },
    ];

    const columns1 = [
        {
            title: "NAME",
            dataIndex: "customerId",
            key: "customerId",
            fixed: "left",
        },
        {
            title: "REFERENCE",
            dataIndex: "reservationStringId",
            key: "reservationStringId",
        },
        {
            title: "STATUS",
            dataIndex: "isCancelled",
            key: "isCancelled",
        },
        {
            title: "SOURCE",
            dataIndex: "SOURCE",
            key: "SOURCE",
        },
        {
            title: "CHANNEL",
            dataIndex: "CHANNEL",
            key: "CHANNEL",
        },
        {
            title: "ROOMS",
            dataIndex: "roomInfo",
            key: "roomInfo",
        },
        {
            title: "CHECK-IN",
            dataIndex: "reservationInDateAndTime",
            key: "reservationInDateAndTime",
        },
        {
            title: "CHECK-OUT",
            dataIndex: "reservationOutDateAndTime",
            key: "reservationOutDateAndTime",
        },
        {
            title: "NUMBER OF NIGHTS",
            dataIndex: "totalNights",
        },
        {
            title: "ROOM CHARGES",
            dataIndex: "ROOM CHARGES",
            key: "ROOM CHARGES",
        },
        {
            title: "TAX",
            dataIndex: "TAX",
            key: "TAX",
        },
        {
            title: "EXTRAS",
            dataIndex: "EXTRAS",
            key: "EXTRAS",
        },
        {
            title: "TOTAL",
            dataIndex: "TOTAL",
            key: "TOTAL",
        },
        {
            title: "PAYMENT RECEIVED",
            dataIndex: "PAYMENT RECEIVED",
            key: "PAYMENT RECEIVED",
        },
        {
            title: "OUTSTANDING",
            dataIndex: "OUTSTANDING",
            key: "OUTSTANDING",
        },
        {
            title: "BOOKED ON",
            dataIndex: "reservationBookTime",
        },
        {
            title: "BOOKED BY",
            dataIndex: "reservationCreatedStaffUserId",
        },
    ];

    const handleDownloadCSV = () => {
        csvLinkRef.current.link.click();
    };

    const handleDownloadPdf = () => {
        let col = [
            {
                title: "NAME",
                dataIndex: "customerId",
                key: "customerId",
            },
            {
                title: "REFERENCE",
                dataIndex: "reservationStringId",
            },

            {
                title: "ROOM CHARGES",
                dataIndex: "payment",
                key: "payment",
                align: "left",
            },
            {
                title: "TAX",
                dataIndex: "payment",
                key: "payment",
            },
            {
                title: "EXTRAS",
                dataIndex: "payment",
                key: "payment",
            },
            {
                title: "TOTAL",
                dataIndex: "payment",
                key: "payment",
            },
            {
                title: "RECEIVED",
                dataIndex: "payment",
                key: "payment",
            },
            {
                title: "OUTSTANDING",
                dataIndex: "payment",
                key: "payment",
            },
        ];

        const doc = new jsPDF();
        doc.text("Outstanding Report", 10, 10);
        doc.autoTable({
            startY: 20,
            head: [col.map((column) => column.title)],
            body: roomReservation.map((row) =>
                col.map((column) =>
                    column.title == "NAME"
                        ? row[column.dataIndex].name
                        : column.title == "ROOM CHARGES"
                        ? row[column.dataIndex].roomCharges
                        : column.title == "TAX"
                        ? row[column.dataIndex].roomChargesTax
                        : column.title == "EXTRAS"
                        ? row[column.dataIndex].extraCharges
                        : column.title == "TOTAL"
                        ? row[column.dataIndex].grandTotal
                        : column.title == "RECEIVED"
                        ? row[column.dataIndex].received
                        : column.title == "OUTSTANDING"
                        ? row[column.dataIndex].grandTotal - row[column.dataIndex].received
                        : row[column.dataIndex]
                )
            ),
        });
        doc.save("Outstanding.pdf");
    };

    const handleDownloadExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(
            roomReservation.map((k) => ({
                NAME: k?.customerId?.name,
                REFERENCE: k.reservationStringId,
                STATUS: k.isReceipt ? "Completed" : k.isCancelled ? "Cancelled" : "Confirmed",
                SOURCE: k?.general?.source,
                CHANNEL: k?.general?.channel,
                ROOMS: k?.roomInfo?.length,
                "CHECK-IN": commonFunction.convertToDate(k.reservationInDateAndTime, "MMM DD, YYYY"),
                "CHECK-OUT": commonFunction.convertToDate(k.reservationOutDateAndTime, "MMM DD, YYYY"),
                "NUMBER OF NIGHTS": k?.totalNights,
                "ROOM CHARGES": k?.payment?.roomCharges,
                TAX: k?.payment?.roomChargesTax,
                EXTRAS: k?.payment?.extraCharges,
                TOTAL: k?.payment?.grandTotal,
                "PAYMENT RECEIVED": k?.payment?.received,
                OUTSTANDING: k?.payment?.grandTotal - k?.payment?.received,
                "BOOKED ON": commonFunction.convertToDate(k.reservationBookTime, "MMM DD, YYYY"),
                "BOOKED BY": k?.reservationCreatedStaffUserId?.username,
            }))
        );

        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        XLSX.writeFile(workbook, "Outstanding.xlsx");
    };

    return (
        <>
            <Main>
                <CardToolbox>
                    <PageHeader
                        ghost
                        className="comman-other-custom-pageheader receipts-top0"
                        subTitle={
                            <>
                                <div className="table_titles">
                                    <h2>Outstanding</h2>
                                    <span className="title-counter">{roomReservation?.length}</span>
                                </div>
                                {/* <div
                  style={{ boxShadow: "none", marginLeft: "10px" }}
                  className="search_lrm"
                >
                  <Input
                    suffix={<SearchOutlined />}
                    autoFocus={deviceWidth && deviceWidth <= 992 ? false : true}
                    placeholder="Search by Mobile no"
                    style={{
                      borderRadius: "30px",
                      width: "250px",
                    }}
                    onChange={(e) => onSearch(e)}
                    onKeyPress={(event) => {
                      if (event.key.match("[0-9]+")) {
                        return true;
                      } else {
                        return event.preventDefault();
                      }
                    }}
                  />
                </div> */}
                            </>
                        }
                        buttons={[
                            <div key="1" className="page-header-actions custom_action">
                                <div className="actionBtn">
                                    <Tooltip
                                        title={
                                            <p
                                                style={{
                                                    textAlign: "center",
                                                    marginTop: "10px",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                Showing report from{" "}
                                                {startDate
                                                    ? moment(startDate).format("MMMM Do YYYY")
                                                    : moment()
                                                          .startOf("month")
                                                          .format("MMMM Do YYYY")}
                                                {" to"}{" "}
                                                {endDate
                                                    ? moment(endDate).format("MMMM Do YYYY")
                                                    : moment()
                                                          .endOf("month")
                                                          .format("MMMM Do YYYY")}
                                            </p>
                                        }
                                    >
                                        <ExclamationCircleOutlined
                                            style={{
                                                cursor: "pointer",
                                                marginRight: "10px",
                                                fontSize: "18px",
                                            }}
                                        />
                                    </Tooltip>
                                    <CalendarButtonPageHeader key="7" type="payment-mode" />
                                </div>
                                <div className="actionBtn">
                                    <Popover placement="bottomLeft" content={content} trigger="click">
                                        <Button size="middle" type="white">
                                            <FeatherIcon icon="download" size={14} />
                                            Export
                                        </Button>
                                    </Popover>
                                </div>
                            </div>,
                        ]}
                    />
                </CardToolbox>
                <CSVLink
                    style={{ display: "none" }}
                    data={roomReservation.map((k) => ({
                        customerId: k?.customerId?.name,
                        reservationStringId: k.reservationStringId,
                        isCancelled: k.isReceipt ? "Completed" : k.isCancelled ? "Cancelled" : "Confirmed",
                        SOURCE: k?.general?.source,
                        CHANNEL: k?.general?.channel,
                        roomInfo: k?.roomInfo?.length,
                        reservationInDateAndTime: commonFunction.convertToDate(k.reservationInDateAndTime, "MMM DD, YYYY"),
                        reservationOutDateAndTime: commonFunction.convertToDate(k.reservationOutDateAndTime, "MMM DD, YYYY"),
                        totalNights: k?.totalNights,
                        "ROOM CHARGES": k?.payment?.roomCharges,
                        TAX: k?.payment?.roomChargesTax,
                        EXTRAS: k?.payment?.extraCharges,
                        TOTAL: k?.payment?.grandTotal,
                        "PAYMENT RECEIVED": k?.payment?.received,
                        OUTSTANDING: k?.payment?.grandTotal - k?.payment?.received,
                        reservationBookTime: commonFunction.convertToDate(k.reservationBookTime, "MMM DD, YYYY"),
                        reservationCreatedStaffUserId: k?.reservationCreatedStaffUserId?.username,
                    }))}
                    headers={[...columns1]
                        .filter((val) => val.dataIndex != "action")
                        .map((column) => ({
                            label: column.title,
                            key: column.dataIndex,
                        }))}
                    filename={"Outstanding.csv"}
                    ref={csvLinkRef}
                >
                    Hidden Download CSV Link
                </CSVLink>
                <Row gutter={15}>
                    <Col md={24}>
                        <Cards headless>
                            <UserTableStyleWrapper>
                                <div className="contact-table">
                                    <TableWrapper className="table-responsive">
                                        {loader ? (
                                            <Table
                                                locale={locale}
                                                rowKey="_id"
                                                size="small"
                                                dataSource={[]}
                                                columns={columns}
                                                fixed={true}
                                                scroll={{ x: 800 }}
                                                pagination={{
                                                    pageSizeOptions: ["10", "50", "100", "500", "1000"],
                                                    showSizeChanger: true,
                                                    total: roomReservation.length,
                                                }}
                                            />
                                        ) : (
                                            <Table
                                                rowKey="_id"
                                                size="small"
                                                dataSource={roomReservation}
                                                columns={columns}
                                                fixed={true}
                                                scroll={{ x: 2500 }}
                                                onRow={(row) => ({
                                                    onClick: () => {
                                                        handleReservation(row);
                                                    },
                                                })}
                                                pagination={{
                                                    pageSizeOptions: ["10", "50", "100", "500", "1000"],
                                                    showSizeChanger: true,
                                                    total: roomReservation.length,
                                                }}
                                            />
                                        )}
                                    </TableWrapper>
                                </div>
                            </UserTableStyleWrapper>
                        </Cards>
                    </Col>
                </Row>
                <Modal title="Export Outstanding" visible={modalVisible} onOk={form.submit} okText={"OK"} onCancel={handleCancel} width={600}>
                    <Form form={form} name="export_customer" onFinish={onSubmit}>
                        <div className="add-product-block">
                            <div className="add-product-content">
                                {loading ? (
                                    <p>It would take about 5 to 10 minutes to export and you will be send by email.</p>
                                ) : (
                                    <Form.Item
                                        name="email"
                                        label="Send to Email Address"
                                        initialValue={email}
                                        rules={[
                                            { message: "Email address is required", required: true },
                                            { type: "email", message: "A valid semail is required" },
                                        ]}
                                    >
                                        <Input />
                                    </Form.Item>
                                )}
                            </div>
                        </div>
                    </Form>
                </Modal>
            </Main>
        </>
    );
};

export default Outstanding;
