import React from "react";
import { Row, Col, Divider, Tooltip, Button, PageHeader } from "antd";
import { Cards } from "../../components/cards/frame/cards-frame";
import { Main } from "../styled";
import { NavLink, useRouteMatch } from "react-router-dom";
import "./hotel.css";
import { useHistory } from "react-router-dom";
import { Figure2, BannerWrapper } from "../Application/Style";
import "../Application/application.css";
import { getItem } from "../../utility/localStorageControl";
const HotelEcommerce = () => {
  const history = useHistory();
  const { path } = useRouteMatch();
  const userDetails = getItem("userDetails");

  return (
    <Main>
      <PageHeader
        style={{ marginBottom: "10px" }}
        ghost
        title="Track down your daily, weekly, monthly performance from all sources and extract reports with ease."
      />
      <Cards headless>
        <Row gutter={25}>
          {userDetails?.business_type == "hotel_management" && (
            <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
              <BannerWrapper>
                <Cards
                  className="mb-70"
                  bodyStyle={{
                    background: "#F3F4F6",
                    borderRadius: "10px",
                    height: "245px",
                  }}
                  headless
                >
                  <Figure2>
                    <figcaption>
                      <div className="cardHeader1">
                        <h2 style={{ color: "#242525" }}>Master Reservation</h2>
                      </div>

                      <p className="textWhite" style={{ color: "#242525" }}>
                        Track your daily,weekly,monthly master reservation from
                        all sources.
                      </p>
                      <Button
                        size="large"
                        type="white"
                        onClick={() => {
                          history.push(`/reports/master`);
                        }}
                      >
                        <span style={{ color: "#242525" }}>View</span>
                      </Button>
                    </figcaption>
                  </Figure2>
                </Cards>
              </BannerWrapper>
            </Col>
          )}
          {userDetails?.business_type == "hotel_management" && (
            <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
              <BannerWrapper>
                <Cards
                  className="mb-70"
                  bodyStyle={{
                    background: "#F3F4F6",
                    borderRadius: "10px",
                    height: "245px",
                  }}
                  headless
                >
                  <Figure2>
                    <figcaption>
                      <div className="cardHeader1">
                        <h2 style={{ color: "#242525" }}>Room Reservations</h2>
                      </div>

                      <p className="textWhite" style={{ color: "#242525" }}>
                        View & track your room wise reservations from different
                        sources.
                      </p>
                      <Button
                        size="large"
                        type="white"
                        onClick={() => {
                          history.push(`/reports/roomReservation`);
                        }}
                      >
                        <span style={{ color: "#242525" }}>View</span>
                      </Button>
                    </figcaption>
                  </Figure2>
                </Cards>
              </BannerWrapper>
            </Col>
          )}
          {userDetails?.business_type == "hotel_management" && (
            <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
              <BannerWrapper>
                <Cards
                  className="mb-70"
                  bodyStyle={{
                    background: "#F3F4F6",
                    borderRadius: "10px",
                    height: "245px",
                  }}
                  headless
                >
                  <Figure2>
                    <figcaption>
                      <div className="cardHeader1">
                        <h2 style={{ color: "#242525" }}>
                          Cancelled Reservation
                        </h2>
                      </div>

                      <p className="textWhite" style={{ color: "#242525" }}>
                        View all cancelled reservations and extract report with
                        ease.
                      </p>
                      <Button
                        size="large"
                        type="white"
                        onClick={() => {
                          history.push(`/reports/roomReservation`, {
                            status: "cancelled",
                          });
                        }}
                      >
                        <span style={{ color: "#242525" }}>View</span>
                      </Button>
                    </figcaption>
                  </Figure2>
                </Cards>
              </BannerWrapper>
            </Col>
          )}
          {userDetails?.business_type == "hotel_management" && (
            <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
              <BannerWrapper>
                <Cards
                  className="mb-70"
                  bodyStyle={{
                    background: "#F3F4F6",
                    borderRadius: "10px",
                    height: "245px",
                  }}
                  headless
                >
                  <Figure2>
                    <figcaption>
                      <div className="cardHeader1">
                        <h2 style={{ color: "#242525" }}>
                          Outstanding Payments
                        </h2>
                      </div>

                      <p className="textWhite" style={{ color: "#242525" }}>
                        View all outstanding reservations and export detailed
                        reports with ease.
                      </p>
                      <Button
                        size="large"
                        type="white"
                        onClick={() => {
                          history.push(`/reports/outstanding`);
                        }}
                      >
                        <span style={{ color: "#242525" }}>View</span>
                      </Button>
                    </figcaption>
                  </Figure2>
                </Cards>
              </BannerWrapper>
            </Col>
          )}
          {userDetails?.business_type == "hotel_management" && (
            <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
              <BannerWrapper>
                <Cards
                  className="mb-70"
                  bodyStyle={{
                    background: "#F3F4F6",
                    borderRadius: "10px",
                    height: "245px",
                  }}
                  headless
                >
                  <Figure2>
                    <figcaption>
                      <div className="cardHeader1">
                        <h2 style={{ color: "#242525" }}>Occupancy</h2>
                      </div>

                      <p className="textWhite" style={{ color: "#242525" }}>
                        Track your daily rooms occupancy and generate detailed
                        reports with ease.
                      </p>
                      <Button
                        size="large"
                        type="white"
                        onClick={() => {
                          history.push(`/reports/occupancy`);
                        }}
                      >
                        <span style={{ color: "#242525" }}>View</span>
                      </Button>
                    </figcaption>
                  </Figure2>
                </Cards>
              </BannerWrapper>
            </Col>
          )}

          <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
            <BannerWrapper>
              <Cards
                className="mb-70"
                bodyStyle={{
                  background: "#F3F4F6",
                  borderRadius: "10px",
                  height: "245px",
                }}
                headless
              >
                <Figure2>
                  <figcaption>
                    <div className="cardHeader1">
                      <h2 style={{ color: "#242525" }}>Sales</h2>
                    </div>

                    <p className="textWhite" style={{ color: "#242525" }}>
                      Track and analyse sales performance from all sources and
                      export detailed reports.
                    </p>
                    <Button
                      size="large"
                      type="white"
                      onClick={() => {
                        history.push(`/reports/dailySale`);
                      }}
                    >
                      <span style={{ color: "#242525" }}>View</span>
                    </Button>
                  </figcaption>
                </Figure2>
              </Cards>
            </BannerWrapper>
          </Col>

          <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
            <BannerWrapper>
              <Cards
                className="mb-70"
                bodyStyle={{
                  background: "#F3F4F6",
                  borderRadius: "10px",
                  height: "245px",
                }}
                headless
              >
                <Figure2>
                  <figcaption>
                    <div className="cardHeader1">
                      <h2 style={{ color: "#242525" }}>Payment Received</h2>
                    </div>

                    <p className="textWhite" style={{ color: "#242525" }}>
                      View all recorded payments and export detailed reports.
                    </p>
                    <Button
                      size="large"
                      type="white"
                      onClick={() => {
                        history.push(`/reports/paymentReceived`);
                      }}
                    >
                      <span style={{ color: "#242525" }}>View</span>
                    </Button>
                  </figcaption>
                </Figure2>
              </Cards>
            </BannerWrapper>
          </Col>
          <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
            <BannerWrapper>
              <Cards
                className="mb-70"
                bodyStyle={{
                  background: "#F3F4F6",
                  borderRadius: "10px",
                  height: "245px",
                }}
                headless
              >
                <Figure2>
                  <figcaption>
                    <div className="cardHeader1">
                      <h2 style={{ color: "#242525" }}>Daily Payments</h2>
                    </div>

                    <p className="textWhite" style={{ color: "#242525" }}>
                      Analyse daily, weekly, monthy payment Mode from different
                      payment mode and extract report.
                    </p>
                    <Button
                      size="large"
                      type="white"
                      onClick={() => {
                        history.push(`/reports/paymentMode`);
                      }}
                    >
                      <span style={{ color: "#242525" }}>View</span>
                    </Button>
                  </figcaption>
                </Figure2>
              </Cards>
            </BannerWrapper>
          </Col>

          <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
            <BannerWrapper>
              <Cards
                className="mb-70"
                bodyStyle={{
                  background: "#F3F4F6",
                  borderRadius: "10px",
                  height: "245px",
                }}
                headless
              >
                <Figure2>
                  <figcaption>
                    <div className="cardHeader1">
                      <h2 style={{ color: "#242525" }}>Product wise</h2>
                    </div>

                    <p className="textWhite" style={{ color: "#242525" }}>
                      View and track your most selling products and categories.
                    </p>
                    <Button
                      size="large"
                      type="white"
                      onClick={() => {
                        history.push(`/reports/productWise`);
                      }}
                    >
                      <span style={{ color: "#242525" }}>View</span>
                    </Button>
                  </figcaption>
                </Figure2>
              </Cards>
            </BannerWrapper>
          </Col>

          <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
            <BannerWrapper>
              <Cards
                className="mb-70"
                bodyStyle={{
                  background: "#F3F4F6",
                  borderRadius: "10px",
                  height: "245px",
                }}
                headless
              >
                <Figure2>
                  <figcaption>
                    <div className="cardHeader1">
                      <h2 style={{ color: "#242525" }}>Invoices</h2>
                    </div>

                    <p className="textWhite" style={{ color: "#242525" }}>
                      View all invoices and export various reports like
                      sales,payment,product wise,order ticket & shift reports.
                    </p>
                    <Button
                      size="large"
                      type="white"
                      onClick={() => {
                        history.push(`/receipts`);
                      }}
                    >
                      <span style={{ color: "#242525" }}>View</span>
                    </Button>
                  </figcaption>
                </Figure2>
              </Cards>
            </BannerWrapper>
          </Col>
          <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
            <BannerWrapper>
              <Cards
                className="mb-70"
                bodyStyle={{
                  background: "#F3F4F6",
                  borderRadius: "10px",
                  height: "245px",
                }}
                headless
              >
                <Figure2>
                  <figcaption>
                    <div className="cardHeader1">
                      <h2 style={{ color: "#242525" }}>Taxes</h2>
                    </div>

                    <p className="textWhite" style={{ color: "#242525" }}>
                      View and track taxes collected from various sources.
                    </p>
                    <Button
                      size="large"
                      type="white"
                      onClick={() => {
                        history.push(`/reports/taxes`);
                      }}
                    >
                      <span style={{ color: "#242525" }}>View</span>
                    </Button>
                  </figcaption>
                </Figure2>
              </Cards>
            </BannerWrapper>
          </Col>
          <Col xxl={6} xl={8} lg={8} sm={12} xs={24} className="appstr_crd">
            <BannerWrapper>
              <Cards
                className="mb-70"
                bodyStyle={{
                  background: "#F3F4F6",
                  borderRadius: "10px",
                  height: "245px",
                }}
                headless
              >
                <Figure2>
                  <figcaption>
                    <div className="cardHeader1">
                      <h2 style={{ color: "#242525" }}>Customers</h2>
                    </div>

                    <p className="textWhite" style={{ color: "#242525" }}>
                      Track your customer history like order count,order
                      value,last purchase & reservation count.
                    </p>
                    <Button
                      size="large"
                      type="white"
                      onClick={() => {
                        history.push(`/customers`);
                      }}
                    >
                      <span style={{ color: "#242525" }}>View</span>
                    </Button>
                  </figcaption>
                </Figure2>
              </Cards>
            </BannerWrapper>
          </Col>
        </Row>
      </Cards>
    </Main>
  );
};

export default HotelEcommerce;
